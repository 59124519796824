// import "./ListFormationsSection2.css";
import React, { useEffect } from "react";
import secureLocalStorage from "react-secure-storage";
import "./FormationCard3.css";

const FormationCard3 = ({
  imageName,
  formation,
  openModal,
  moreDialogRef,
  moreScrollableRef,
  contactDialogRef,
  contactScrollableRef,
  updateCart,
  cart,
  setCart,
}) => {
  const [isInCart, setIsInCart] = React.useState(
    cart.filter((e) => e.course_reference == formation.course_reference)
      .length == 0
      ? false
      : true
  );

  // useEffect(()=>{
  //
  //   setCart( secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : [])
  //   setIsInCart((cart.filter((e)=>e.course_reference==formation.course_reference).length==0 ? false: true))
  //
  // },[formation])

  useEffect(() => {
    setIsInCart(
      cart.some((e) => e.course_reference === formation.course_reference)
    );
  }, [formation, cart]);

  // const handleAddCart = ()=>{
  //
  //   if(isInCart){
  //     setIsInCart(false)
  //     const cartuse=secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : []
  //     const newcart = cartuse.filter((e)=>e.course_reference !=formation.course_reference)
  //     secureLocalStorage.setItem("kids_cart", JSON.stringify(newcart));
  //     updateCart(newcart)
  //
  //
  //   }else{
  //     setIsInCart(true)
  //     const cartuse=secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : []
  //     cartuse.push({...formation,nbr_enfant:1,date_choisi:formation.formation_dates[0]})
  //
  //     secureLocalStorage.setItem("kids_cart", JSON.stringify(cartuse));
  //   }
  //
  // }

  const handleAddCart = () => {
    let cartuse = secureLocalStorage.getItem("kids_cart")
      ? JSON.parse(secureLocalStorage.getItem("kids_cart"))
      : [];

    if (isInCart) {
      // Remove from cart
      const newcart = cartuse.filter(
        (e) => e.course_reference !== formation.course_reference
      );
      secureLocalStorage.setItem("kids_cart", JSON.stringify(newcart));
      updateCart(newcart); // Update cart in FormationList3
    } else {
      // Add to cart
      cartuse.push({
        ...formation,
        nbr_enfant: 1,
        date_choisi: formation.formation_dates[0],
      });
      secureLocalStorage.setItem("kids_cart", JSON.stringify(cartuse));
      updateCart(cartuse); // Update cart in FormationList3
    }
  };

  // // Utiliser import dynamique pour charger l'image dynamiquement
  // const imageUrl = formation.image_url
  //   ? new URL(formation.image_url, import.meta.url).href
  //   : null;

  let imageUrl;
  try {
    imageUrl = require(`../../../../media/images/${imageName}`);
  } catch (error) {
    console.error("Image not found:", imageName);
    imageUrl = null;
  }

  return (
    <div className="formation-card-2">
      <h3>{formation.formation_title}</h3>
      {imageUrl && (
        <img
          src={imageUrl}
          alt={formation.formation_title}
          className="formation-card-image-2"
        />
      )}
      <p className="description-formation-2">{formation.description}</p>
      <div className="formation-card-buttons-2">
        <button
          className="formation-card-button-2"
          onClick={() =>
            openModal(contactDialogRef, contactScrollableRef, formation)
          }
        >
          Contact
        </button>
        <button
          href="#"
          className="formation-card-link-2"
          onClick={() => openModal(moreDialogRef, moreScrollableRef, formation)}
        >
          En savoir plus
        </button>
        <button
          className={`add-cart-btn ${isInCart ? "remove" : "add"}`}
          onClick={handleAddCart}
        >
          {isInCart ? "Retirer du Panier" : "Ajouter Au panier"}
        </button>
      </div>
    </div>
  );
};

export default FormationCard3;
