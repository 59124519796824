import "./validationPage.css";
import Strie from "../../components/Strie/Strie";
import ValidationForm from "./validation-form/ValidationForm";
//import ValidationCommande from "./validation-commande/ValidationCommande";
import React, { useRef } from "react";
import { PayPalScriptProvider } from "@paypal/react-paypal-js";
import { Link } from "react-router-dom";
import { IoArrowBackCircleOutline } from "react-icons/io5";

function ValidationPage() {

    const formRef = useRef(null);

    const initialOptions = {
        "client-id": process.env.REACT_APP_PAYPAL_CLIENT_ID,
        currency: "EUR",
        intent: "capture",
    };

    return (
        <PayPalScriptProvider options={initialOptions}>
            <div className="layout">
                <div className="validation-container">
                    <ValidationForm />
                    <div className="push-to-bottom">
                        <Link to={'/cart'} className="link">
                            <IoArrowBackCircleOutline size={30} className="icon" color="#97179c" />
                            Retour au panier
                        </Link>
                    </div>
                </div>
                <Strie scale={4}
                    color="red"
                    left="-25%"
                    right="-23%"
                    top="56%"
                    zindex={-1} />
                <Strie scale={2}
                    color="red"
                    left="-23%"
                    top="70%"
                    zindex={-1} />
                {/*bottom*/}
                <Strie scale={2}
                    color="blue"
                    left="114%"
                    top="16%"
                    right="0"
                    zindex={-1} />
                <Strie scale={4}
                    color="blue"
                    left="112%"
                    top="38%"
                    right="0"
                    zindex={-1} />
            </div>
        </PayPalScriptProvider>
    );
}

export default ValidationPage;
