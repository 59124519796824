import "./CategorieSection.css";
import Card from "../../../../components/Card/Card";

import KidsImage1 from "../../../../media/images/kid-categorie-image-1.jpg";
import KidsImage2 from "../../../../media/images/kid-categorie-image-2.jpg";
// import KidsImage3 from "../../../../media/images/kid-categorie-image-3.jpg";
import KidsImage3 from "../../../../media/images/kid-categorie-image-5.png";
import KidsImage4 from "../../../../media/images/kid-formation-background.jpg";

function CategorieSection() {
  return (
    <div className="categorie-section-wrapper">
      <div className="categorie-section-header">
        <h2 className="categorie-section-title">
          Des ateliers en présentiel ou en distanciel pour apprendre à coder
          toute l’année
        </h2>
        <p className="categorie-section-text">
          Des <span className="bold">ateliers de codage</span> sont organisés
          tout au long de l’année et proposent des cours{" "}
          <span className="bold">
            pour 3 différentes tranches d’âge et niveaux
          </span>
          . Ils comprennent des activités ludiques (en présentiel avec les
          studios fond vert & podcasting) , des technologies à découvrir et des
          défis à relever.
        </p>
        <p className="categorie-section-text">
          A l’issue de leurs stages, vos enfants/adolescents obtiendront une{" "}
          <span className="bold">micro-certification</span> sécurisée via la{" "}
          <span className="bold">blockchain</span>.
        </p>
      </div>
      <div className="categorie-section-container">
        <Card
          icon={KidsImage3}
          icon_width="256px"
          title="L’Hacienda en Présentiel !"
          href={"/kids"}
        >
          <p>
            Les enfants jusqu'à <span className="bold">14 ans</span> sont
            accueillis <span className="bold">exclusivement en présentiel</span>{" "}
            à l’Hacienda : un apprentissage ne peut se faire qu'en présence
            d'adultes. <br /> <span className="bold">De 14 à 18 ans</span>, nous
            vous laissons le choix : entre le présentiel et le distanciel.
          </p>
        </Card>
        <Card
          icon={KidsImage1}
          icon_width="215px"
          title="Deviens un Wyzer !"
          href={"/kids"}
        >
          <p>
            Pendant les vacances scolaires, vos enfants et vos ados ont le choix
            : de la <span className="bold">création de sites web</span> en
            passant par la programmation de{" "}
            <span className="bold">jeux vidéos</span>, de l’expérimentation de
            l'<span className="bold">intelligence artificielle</span> et de la{" "}
            <span className="bold">cybersécurité</span>, ils deviennent de vrais
            Wyzers !
          </p>
        </Card>
        <Card
          icon={KidsImage2}
          icon_width="256px"
          title="Devenir un expert du Web !"
          href={"/ado"}
        >
          <p>
            Hors vacances scolaires, nous proposons des{" "}
            <span className="bold">formations par niveaux</span>, à l’Hacienda
            ou en distanciel,{" "}
            <span className="bold">en semaine et/ou le week-end</span>, à vos
            adolescents en développement web, marketing, IA, cybersécurité.
          </p>
        </Card>
      </div>
    </div>
  );
}

export default CategorieSection;
