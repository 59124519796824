import { useRef, useEffect, useState } from "react";

import "./HaciendaSection.css";
//import Strie from "../../../../components/Strie/Strie";

import IconNext from "../../../../media/icons/IconNext";
import HaciendaImage1 from "../../../../media/images/hacienda-image-1.jpg";
import HaciendaImage2 from "../../../../media/images/hacienda-image-2.jpg";
import Accordion from "../../../../components/Accordion/Accordion";

function HaciendaSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`hacienda-section-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="hacienda-section-description">
          <div className="hacienda-section-header">
            <div>
              <h1 className="hacienda-title">Wyze hacienda</h1>
              <h2 className="semi-bold hacienda-section-title">
                A l’Hacienda de Saint-Ouen
              </h2>
            </div>
            <p className="hacienda-text">
              Notre centre de formation à Saint-Ouen-Sur-Seine est un espace
              convivial et stimulant, où les enfants et les adolescents peuvent
              apprendre, créer et s’épanouir tout en s’amusant.
            </p>
          </div>
          <a
            href="https://hacienda-studio.com/"
            className="hero-section-button  margin"
            target="_blank" rel="noopener noreferrer"
          >
            <span className="hacienda-section-button-weight">
              Découvrir l'Hacienda Studio
            </span>
            <IconNext width={22} height={22} />
          </a>
          <div className="hacienda-section-body">
            <Accordion title="Création vidéo sur fond vert">
              <div className="hacienda-section-body-container">
                <p className="hacienda-section-body-text">
                  Les enfants apprendront à créer des vidéos professionnelles en
                  utilisant un fond vert. Ils pourront explorer les techniques
                  de montage vidéo, d’animation et d’effets spéciaux pour donner
                  vie à leurs idées.
                </p>
                <a
                  href="https://hacienda-studio.com/location-studio-fond-vert-paris/"
                  className="hero-section-button"
                  target="_blank" rel="noopener noreferrer"
                >
                  <span className="hero-section-button-weight white-space">
                    Le studio fond vert de l'Hacienda
                  </span>
                  <IconNext width={22} height={22} />
                </a>
              </div>
            </Accordion>
            <Accordion title="Podcasting">
              <div className="hacienda-section-body-container">
                <p className="hacienda-section-body-text">
                  Les enfants découvriront le monde du podcasting, de la
                  création de contenu à la diffusion en ligne. Ils pourront
                  enregistrer leur propre podcast et partager leurs idées avec
                  le monde entier.
                </p>
                <a
                  href="https://hacienda-studio.com/location-studio-podcast-webcast"
                  className="hero-section-button white-space"
                  target="_blank" rel="noopener noreferrer"
                >
                  <span className="hero-section-button-weight white-space">
                    Le studio podcast de l'Hacienda
                  </span>
                  <IconNext width={22} height={22} />
                </a>
              </div>
            </Accordion>
            <Accordion title="Prévention à la surexposition aux écrans">
              <div className="hacienda-section-body-container">
                <p className="hacienda-section-body-text">
                  Les enfants apprendront les risques liés à l’addiction aux
                  écrans, notamment les troubles du sommeil et les effets des
                  contenus violents... En partenariat avec l’association CoSE.
                </p>
                <a
                  href="https://surexpositionecrans.fr/"
                  className="hero-section-button"
                  target="_blank" rel="noopener noreferrer"
                >
                  <span className="hero-section-button-weight white-space">
                    Découvrir l'association CoSE
                  </span>
                  <IconNext width={22} height={22} />
                </a>
              </div>
            </Accordion>
          </div>
        </div>

        <div className="kid-section-illustration">
          <img
            className="kid-section-image"
            src={HaciendaImage1}
            alt="team work1"
          ></img>
          <div className="kid-section-container-circle">
            <img
              className="kid-section-image-circle"
              src={HaciendaImage2}
              alt="team work2"
            ></img>
          </div>
        </div>
      </div>
      {/*<Strie scale={1.5} color="blue" left="-290px" top="0px" />*/}
      {/*<Strie scale={3} color="blue" left="-250px" top="100px" />*/}
      {/*<Strie scale={12} color="red" left="100%" top="0%" zindex={-1} />*/}
      {/*<Strie scale={3} color="red" left="110%" top="20%" zindex={-1} />*/}
    </div>
  );
}

export default HaciendaSection;
