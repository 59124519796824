import {Link, useNavigate} from "react-router-dom";
import "./Cart.css";
import { IoArrowBackCircleOutline } from "react-icons/io5";
import data from './data.json'
import CartElement from "../../components/CartElement/CartElement";
import paypalogo from '../../media/images/paypal_logo.png'
import Strie from "../../components/Strie/Strie";
import React, {useEffect, useState} from "react";
import  secureLocalStorage  from  "react-secure-storage";
import emptyIcon from '../../media/images/Basket-v3.png'
//import emptyCercle from '../../media/images/404-cercle.png'
import {useGlobalContext} from "../../context";

function Cart() {
console.log(data)
    const { cart, updateCart, setCart } = useGlobalContext();
  const navigate = useNavigate();
//   const [cart, setCart] = useState(
//
//     secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : []
//
// )


    const [total, setTotal] = useState(0)

    const [isChecked, setIsChecked] = useState(false)

    // Update total when cart changes
    useEffect(() => {
        const calculateTotal = cart.reduce((acc, curr) => acc + (curr.formation_prix * curr.nbr_enfant || 0), 0);
        setTotal(calculateTotal);
        secureLocalStorage.setItem('kids_cart', JSON.stringify(cart)); // Keep localStorage in sync
    }, [cart]);


    const handleCheckbox = (e) => {
        setIsChecked(e.target.checked);
    };

    const deleteItem = (course_reference) => {
        const updatedCart = cart.filter((item) => item.course_reference !== course_reference);
        updateCart(updatedCart);
    };

    const ProceedPayement = () => {
        if (!isChecked) return;
        secureLocalStorage.setItem("kids_cart_total", total);
        navigate('/validation', { state: { cart, total } });
    };


    if(cart.length === 0){
        return (
            <section className="cart-empty-container">
                <div className="cart-text">
                    <div className="image-container">
                        <img src={emptyIcon}
                             className="cart-empty-img"
                             alt="empty-cart"/>

                    </div>
                    <p className="cart-empty-text"> Panier Vide. Essayez d'ajouter une formation.</p>
                    <Link className="cart-empty-link" to='/#formation-section'><IoArrowBackCircleOutline size={28} className="icon" color="#97179c"/> Continuer Vos Achats</Link>
                </div>
                <Strie scale={3}
                       color="blue"
                       top="55%"
                       left="-3%"
                       zindex="-1"
                />
                <Strie scale={5}
                       color="blue"
                       top="68%"
                       zindex= "-1"
                       left="1%"/>
                <Strie scale={5}
                       color="red"
                       left="unset"
                       top="31%"
                       zindex="-1"
                       right="1%"/>
                <Strie
                    scale={3}
                    color="red"
                    left="unset"
                    right="-3%"
                    top="45%"
                    zindex="-1"
                />
            </section>
        )
    }

    return (

        <main className="cart">
            <div className="cart-wrapper">
                <div className="cart-container">
                    <div className="cart-grid">
                        <section className="panier">
                            <h2>Panier</h2>
                            <div className="elements-cart-container">
                                {cart.map((item) => (
                                        <CartElement
                                            key={item.course_reference}
                                            element={item}
                                            deleteItem={deleteItem}
                                            updateCart={updateCart}
                                        />
                                    )
                                )}
                            </div>
                            <div className="push-to-bottom">
                                <Link to={'/#formation-section'} className="link"><IoArrowBackCircleOutline size={28} className="icon" color="#97179c"/> Continuer Vos Achats</Link>
                            </div>
                        </section>

                        <section className="total">
                            <div>
                                <h3>Total
                                    <span className="sum">{total}€</span>
                                </h3>
                                <div>
                                    <input type="checkbox"
                                           checked={isChecked}
                                           onChange={handleCheckbox}
                                    />
                                    <label>Je suis d'accord avec les termes et <Link className="cart-mection-legal" to="/mentions-legales">les conditions</Link></label>
                                </div>
                                {isChecked && cart.length > 0 ? <button onClick={ProceedPayement}
                                                                        className="btn">
                                    Passer Au Payement
                                </button> : (
                                    <button className="btn disabled-btn"
                                            disabled>
                                        Passer Au Payement
                                    </button>
                                )}

                                <p className="center-paypal">Nous Acceptons</p>
                                <img src={paypalogo} alt="paypal-logo"/>
                            </div>
                        </section>
                    </div>
                </div>
            </div>
            <Strie scale={1}
                   color="blue"
                   top="60%"
                   left="-4%"
                   zindex={-1}/>
            <Strie scale={2}
                   color="blue"
                   top="70%"
                   zindex={-1}
                   left="-2%"/>
            <Strie scale={2}
                   color="red"
                   left="unset"
                   top="10%"
                   zindex={-1}
                   right="-2%"/>
            <Strie
                scale={1}
                color="red"
                left="unset"
                right="-4%"
                top="20%"
                zindex={-1}
            />

        </main>

    );
}

export default Cart;
