import "./Card.css";
import { Link } from "react-router-dom";

function Card({ icon, title, icon_width, children, href }) {
  return (
    <Link className="card-container-anchor" to={href}>
      <img
        className="card-container-image"
        src={icon}
        alt={icon}
        width={"100%"}
      ></img>
      <p className="card-container-description">{children}</p>
      <h1 className="semi-bold card-container-title">{title}</h1>
    </Link>
  );
}

export default Card;
