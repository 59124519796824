import "./DiscoverSection.css";

import Strie from "../../../../components/Strie/Strie";
function DiscoverSection() {
  return (
    <div className="kids-page-paragraph-upper-container">
      {/* <div className="kids-page-paragraph-container">
        <p>Découvrez nos programmes passionnants dans les domaines de la programmation, de la robotique, de la conception de jeux et de l'intelligence artificielle.</p>
      </div> */}
      <Strie scale={3} color="red" left="-14%" top="10%" zindex={10} />
      <Strie scale={3} color="blue" left="100%" top="80%" zindex={10} />
    </div>
  );
}

export default DiscoverSection;
