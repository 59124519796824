import "./AtelierCard.css";
import label from "../../../../../media/images/label.png";
import Contact from "../../../../../components/Contact/Contact";
import React, {useEffect, useRef, useState} from "react";

import KidsFormationInfoDetails from "./KidsFormationInfoDetails";

import GridComponents from "./GridComponent/GridComponent";
import secureLocalStorage from "react-secure-storage";
import {useGlobalContext} from "../../../../../context";

function AtelierCard({ img, title, desc, formation, ticket }) {
  const moreDialogRef = useRef();
  const moreScrollableRef = useRef();

  const contactDialogRef = useRef();
  const contactScrollableRef = useRef();

 

//start naglaa
  const { cart, updateCart,setCart } = useGlobalContext();

  //check if the course is in the cart or not
  const [isInCart, setIsInCart] = useState(
      cart.some((e) => e.course_reference === formation.course_reference)
  );

  // useEffect(() => {
  //   setIsInCart(
  //       cart.some((e) => e.course_reference === formation.course_reference)
  //   );
  // }, [formation, cart]);


  const handleAddCart = () => {
    let cartuse = [...cart]; // Use the cart from context

    if (isInCart) {
      // Remove from cart
      const newcart = cartuse.filter(
          (e) => e.course_reference !== formation.course_reference
      );
      secureLocalStorage.setItem("kids_cart", JSON.stringify(newcart));
      updateCart(newcart); // Update cart in FormationList3
      setIsInCart(false);
    } else {
      // Add to cart
      cartuse.push({
        ...formation,
        nbr_enfant: 1,
        date_choisi: formation.formation_dates[0],
      });
      secureLocalStorage.setItem("kids_cart", JSON.stringify(cartuse));
      updateCart(cartuse); // Update cart in FormationList3
      setIsInCart(true);
    }
  };
  

  const openModal = (ref, scrollRef) => {
    ref.current?.showModal();
    document.body.style.overflow = "hidden";
    scrollRef.current.scrollTo(0, 0);
  };

  const closeModal = (ref) => {
    ref.current?.close();
    document.body.style.overflow = "unset";
  };

  return (
      <div>
        <div className="atelier-card-container-anchor">
          <GridComponents images={img} />
          <span className={`label2 ${ticket ? "hide" : ""}`}>
          <span>
            {formation.formation_age}
            <br /> ans
          </span>
          <img src={label} />
        </span>

          <h3>{formation.formation_title}</h3>
          <span className={`label2 ${ticket ? "" : "hide"}`}>
          <span>
            {formation.formation_age}
            <br /> ans
          </span>
          <img src={label} />
        </span>
          <p>{formation.formation_description}</p>
          <div className="btn-wrapper">
            <button
                className="atelier-card-botton"
                onClick={() => {
                  openModal(moreDialogRef, moreScrollableRef);
                }}
            >
              En Savoir Plus
            </button>
            <button className={`add-cart-btn ${isInCart ? 'remove' : 'add'}`}
                    onClick={handleAddCart}>
              {isInCart ? 'Retirer du Panier' : 'Ajouter Au panier'}
            </button>
          </div>
        </div>
        <dialog className="formation-kids-more-modal"
                ref={moreDialogRef}>
          <div
              ref={moreScrollableRef}
              className="formation-kids-more-modal-wrapper"
          >
            <KidsFormationInfoDetails formation={formation} />
          </div>
          <button
              className="kids-formation-details-close-button"
              onClick={() => closeModal(moreDialogRef)}
          >
            &#x2715;
          </button>
        </dialog>
        <dialog
            id="dialooog"
            className="formation-kids-contact-modal"
            ref={contactDialogRef}
        >
          <div
              ref={contactScrollableRef}
              className="formation-kids-contact-modal-wrapper"
          >
            <Contact subject={formation.formation_title} />
          </div>
          <button
              className="kids-formation-details-close-button"
              onClick={() => closeModal(contactDialogRef)}
          >
            &#x2715;
          </button>
        </dialog>
      </div>
  );
}

export default AtelierCard;
