import React, { useEffect, useState } from "react";
import "./KidsFormationInfoDetails.css";
import WyzeLogo from "../../../../../media/images/logoKidsTransparentWhite.png";
import testHeaderImage from "../../../../../media/images/formations_images/initiation_a_la_programmation_niveau_1_header_image.png";
import testFooterImage from "../../../../../media/images/formations_images/initiation_a_la_programmation_niveau_1_footer_image.png";
import testPetitFooterImage from "../../../../../media/images/petit-explorateur-footer.png";
import testCreatorFooterImage from "../../../../../media/images/creater-de-demain-footer.png";
import testPetitHeaderImage from "../../../../../media/images/petit-explorateur-header.png";
import testCreatorHeaderImage from "../../../../../media/images/creater-de-demain-header.png";

import petitExplorateurPDF from "../../../../../media/brochures/brochure_petit_explorateur.pdf";
import createurDemainPDF from "../../../../../media/brochures/brochure_createur_demain.pdf";


const getPdf = (formationTitle) => {
  switch (formationTitle) {
    case "Petit Explorateur":
        return petitExplorateurPDF;
        break;
    case "Créateur de Demain":
        return createurDemainPDF;
        break;
    default:
        return null;
  }
}

const handleDownload = (formationTitle) => {
  const pdfFile = getPdf(formationTitle);
  if (!pdfFile) {
    console.error("File not found");
    return;
  }

  const link = document.createElement("a");
  link.href = pdfFile;
  link.setAttribute("download", `${formationTitle}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
}



function KidsFormationInfoDetails({ formation }) {
  const [imageHeaderFormation, setImageHeaderFormation] = useState(null);
  const [imageFooterFormation, setImageFooterFormation] = useState(null);


  const getImage = async (formationTitle, type) => {
    const fileName = formationTitle
      .replace(/\s+/g, "_")
      .toLowerCase()
      .replace(/é/g, "e")
      .replace(/à/g, "a");

    try {
      const imageModule = await import(
        `../../../../../media/images/formations_images/${fileName}_${type}_image.png`
      );
      return imageModule.default;
    } catch (error) {
      //   console.error("Error loading image:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      //   if (formation.formation_type === "longue") {
      const src = await getImage(formation.formation_title, "header");
      setImageHeaderFormation(src);
      //   }
    };

    loadImage();
  });

  useEffect(() => {
    const loadImage = async () => {
      //   if (formation.formation_type === "longue") {
      const src = await getImage(formation.formation_title, "footer");
      setImageFooterFormation(src);
      //   }
    };

    loadImage();
  });

  return (
    <div>
      <div className="kids-formation-container">
        <div className="kids-formation-header">
          <img
            className="kids-formation-header-image"
            // src={imageHeaderFormation}
            src={formation?.formation_title === "Petit Explorateur" ? testPetitHeaderImage : testCreatorHeaderImage }
            alt="header formation"
          />
          <div className="kids-formation-header-left">
            <img src={WyzeLogo} width={"50%"} alt="Wyze logo" />
            <h1 className="kids-formation-longue-span-formation">
              {"Formation "}
            </h1>
            {formation?.formation_title && (
              <h1 className="kids-formation-longue-header-title">
                {formation?.formation_title}
              </h1>
            )}
          </div>
        </div>
        <div className="kids-formation-body">
          <div className="kids-formation-info"></div>
          <div className="kids-formation-competences">
            <h1 className="kids-formation-competences-title">
              Programme de la formation
            </h1>
            {formation?.formation_competences_attestees?.map(
              (competences, idx) => (
                <div
                  key={idx}
                  className="kids-formation-longue-competence-container"
                >
                  <h2 className="kids-formation-longue-competence-title">
                    {competences?.titre}
                  </h2>
                  <ul className="kids-formation-longue-competence-list">
                    {competences?.competences?.map((competence, idx) => (
                      <li
                        key={idx}
                        className="kids-formation-longue-competence-list-item"
                      >
                        {competence}
                      </li>
                    ))}
                  </ul>
                </div>
              )
            )}
          </div>
          <div className="kids-formation-price">
            <div className="kids-formation-price-right">
              <h1>Prix :</h1>
              <h1>
                {formation?.formation_prix?.toLocaleString("fr-FR")}
                {" €"}
              </h1>
              <ul className="kids-formation-price-list">
                <li>Paiement jusqu'à 4 fois sans frais </li>
              </ul>
            </div>
            <img
              className="kids-formation-footer-image"
              //   src={imageFooterFormation}
              src={formation?.formation_title === "Petit Explorateur" ? testPetitFooterImage : testCreatorFooterImage }
              //src={testFooterImage}
              alt="footer formation"
            />
            {/* <Strie scale={3} color="red" left="-8%" top="50%" zindex={1} /> */}
            {/* <Strie scale={13} color="red" left="68%" top="-80%" zindex={1} /> */}
            {/* <Strie scale={1.5} color="red" left="40%" top="50%" zindex={1} /> */}
          </div>
        </div>
        <div className="kids-formation-footer">
          <a
            href="tel:+33 7 45 62 88 26"
            className="kids-formation-contact-button"
          >
            Contacter
          </a>
         <button
            className="kids-formation-download-button "
            onClick={() => handleDownload(formation?.formation_title)}
          >
            Télécharger le programme
          </button>
        </div>
      </div>
    </div>
  );
}

export default KidsFormationInfoDetails;
