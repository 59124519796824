import { useState, useRef } from "react";
import "./Form.css";
import PhoneInput from "react-phone-number-input";
import "react-phone-number-input/style.css";

export default function Form({ currentSubject = "" }) {
  const [nom, setNom] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [email, setEmail] = useState("");
  const [subject, setSubject] = useState(currentSubject);
  const [messageContent, setMessageContent] = useState("");
  const [messageLength, setMessageLength] = useState(0);
  const [notification, setNotification] = useState({ type: "", message: "" });

  const maxMessageLength = 180;
  const nomRef = useRef(null);
  const emailRef = useRef(null);
  const phoneNumberRef = useRef(null);
  const subjectRef = useRef(null);
  const messageRef = useRef(null);

  const baseUrl = "https://server1.wyze-kids.com";

  const handleSubmit = async (event) => {
    event.preventDefault();

    let errorMessage = "";

    if (!nom) {
      errorMessage = "Veuillez entrer votre nom.";
      nomRef.current.focus();
    } else if (!email) {
      errorMessage = "Veuillez entrer votre email.";
      emailRef.current.focus();
    } else if (!phoneNumber) {
      errorMessage = "Veuillez entrer votre numéro de téléphone.";
      phoneNumberRef.current.focus();
    } else if (!subject) {
      errorMessage = "Veuillez sélectionner un sujet.";
      subjectRef.current.focus();
    } else if (!messageContent) {
      errorMessage = "Veuillez entrer votre message.";
      messageRef.current.focus();
    }

    if (errorMessage) {
      setNotification({ icon: "❌", type: "error", message: errorMessage });
      clearNotification();
      return;
    }

    try {
      const res = await fetch(`${baseUrl}/email`, {
        method: "POST",
        body: JSON.stringify({
          email,
          subject,
          message: messageContent,
          nom,
          phoneNumber,
        }),
        headers: {
          "Content-Type": "application/json",
        },
      });

      if (res.ok) {
        setNotification({
          icon: "✅",
          type: "success",
          message: "Votre demande a été envoyé avec succès !",
        });
        setEmail("");
        setSubject("");
        setMessageContent("");
        setNom("");
        setPhoneNumber("");
      } else {
        const data = await res.json();
        setNotification({ type: "error", message: data.message });
      }
    } catch (error) {
      console.error("Erreur lors de l'envoi de l'email :", error);
      setNotification({
        icon: "❌",
        type: "error",
        message: "Une erreur s'est produite lors de l'envoi de l'email.",
      });
    }
    clearNotification();
  };

  const clearNotification = () => {
    setTimeout(() => {
      setNotification({ type: "", message: "" });
    }, 5000);
  };

  return (
    <form className="form-container" onSubmit={handleSubmit}>
      {notification.message && (
        <div className={`notification ${notification.type}`}>
          <p>{notification.icon}</p>
          <p>{notification.message}</p>
        </div>
      )}

      <div className="userbox">
        <input
          name="name"
          type="text"
          id="name"
          ref={nomRef}
          value={nom}
          onChange={(e) => setNom(e.target.value)}
          autoComplete="false"
          required
        />
        <label
          htmlFor="name"
          className={nom === "" ? "animate-label" : "fixed-label"}
        >
          Nom et Prénom *
        </label>
      </div>

      <div className="userbox">
        <PhoneInput
          className="phone-number"
          name="phoneNumber"
          id="phoneNumber"
          ref={phoneNumberRef}
          value={phoneNumber}
          onChange={setPhoneNumber}
          defaultCountry="FR"
          international={true}
          withCountryCallingCode={true}
          required
        />

        <label
          className={`phone-label ${
            phoneNumber === "" ? "animate-label" : "fixed-label"
          }`}
          htmlFor="phoneNumber"
        >
          Numéro de téléphone *
        </label>
      </div>

      <div className="userbox">
        <input
          name="email"
          type="email"
          id="email"
          ref={emailRef}
          value={email}
          onChange={(e) => setEmail(e.target.value)}
          autoComplete="false"
          required
        />
        <label
          htmlFor="email"
          className={email === "" ? "animate-label" : "fixed-label"}
        >
          Mail
        </label>
      </div>

      <div className="userbox">
        <select
          id="subject"
          ref={subjectRef}
          className="custom-select"
          name="subject"
          value={subject}
          onChange={(e) => setSubject(e.target.value)}
        >
          <option value={currentSubject}>
            {currentSubject === ""
              ? "Choisissez un sujet..."
              : "Formation : " + currentSubject}
          </option>
          <option value="inscription">Inscrire mon enfant/ado</option>
          <option value="information">Demande de renseignements</option>
          {/* <option value="other">Autre</option> */}
        </select>
      </div>

      <div className="userbox">
        <textarea
          name="message"
          id="message"
          ref={messageRef}
          value={messageContent}
          onChange={(e) => {
            setMessageContent(e.target.value);
            setMessageLength(e.target.value.length);
          }}
          required
          maxLength={maxMessageLength}
        ></textarea>
        <label
          htmlFor="message"
          className={messageContent === "" ? "animate-label" : "fixed-label"}
        >
          Votre message
        </label>
        <p className="length">{messageLength} / 180</p>
      </div>

      <button className="btn-contact" id="btn-contact" type="submit">
        Envoyer
      </button>
    </form>
  );
}
