import React, { useState } from "react";
import IconNext from "../../media/icons/IconNext";
import "./Accordion.css";

function Accordion({
  title,
  center = "",
  textWrap = "wrap",
  textColor = "grey",
  children,
  border = "",
}) {
  const [isResponseVisible, setResponseVisible] = useState(false);

  const toggleVisibility = () => {
    setResponseVisible((prevVisible) => !prevVisible);
  };

  return (
    <div className="accordion-container">
      <div
        onClick={toggleVisibility}
        className={`accordion-container-header ${
          isResponseVisible ? "selected" : ""
        }`}
        style={{ color: textColor, border: border }}
      >
        <div>
          <IconNext
            width={35}
            height={35}
            key={isResponseVisible}
            rotation={`${isResponseVisible ? "rotate-up" : "rotate-down"}`}
          />
        </div>

        <h6
          className={`accordion-container-title accordion-${center}`}
          style={{ textWrap: textWrap }}
        >
          {title}
        </h6>
      </div>
      <div
        // key={isResponseVisible}
        className={`accordion-container-body`}
        expanded={`${isResponseVisible ? "visible" : "hide"}`}
      >
        {children}{" "}
      </div>
    </div>
  );
}

export default Accordion;
