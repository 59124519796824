import React, { useState, useEffect } from "react";
import "./FormationSection.css";
import Strie from "../../../../components/Strie/Strie";
import FormationCard from "./components/FormationCard";
import AdosBgImage from "../../../../media/images/formations-ados-1.jpg";
import KidsBgImage from "../../../../media/images/formations-kids-1.jpg";

function FormationSection() {
    const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

    useEffect(() => {
        const handleMouseMove = (event) => {
            setCursorPosition({
                x: event.clientX,
                y: event.clientY,
            });
        };

        window.addEventListener("mousemove", handleMouseMove);

        // Cleanup the event listener on component unmount
        return () => {
            window.removeEventListener("mousemove", handleMouseMove);
        };
    }, []);

    return (
        <section id="formation-section" className="formation-section-wrapper">
            <div
                className="formation-section-triangle"
                style={{ left: cursorPosition.x }}
            ></div>
            <div className="formation-section-container">
                <h2 className="semi-bold formation-section-title">Nos formations</h2>
                <p className="formation-section-text">
                    Retrouvez d’un simple coup d’oeil toutes les formations dispensées
                    chez Wyze Academy.
                </p>
                <div className="formation-section-details">
                    <FormationCard
                        formation_link="kids"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. ed non gravida libero. Mauris luctus orci a posuere mattis."
                        title="Kids"
                        subtitle="Découvrir nos stages"
                        image={KidsBgImage}
                    />
                    <FormationCard
                        formation_link="ado"
                        description="Lorem ipsum dolor sit amet, consectetuer adipiscing elit. ed non gravida libero. Mauris luctus orci a posuere mattis."
                        title="Ados"
                        subtitle="Découvrir nos stages"
                        image={AdosBgImage}
                    />
                </div>
            </div>
            <Strie scale={1.5} color="blue" left="-20%" top="20%" />
            <Strie scale={2} color="blue" left="-20%" top="30%" />
            <Strie scale={3} color="red" left="110%" top="0%" />
        </section>
    );
}

export default FormationSection;
