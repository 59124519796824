import React, {useRef, useState} from "react";
import {
  FaFacebook,
  FaInstagram,
  FaLinkedin,
  FaYoutube,
  FaTiktok,
  FaWhatsapp,
} from "react-icons/fa";
import { BiHandicap } from "react-icons/bi";
import "./Footer.css";
import { Link } from "react-router-dom";
import logo from "../../media/images/logoKidsTransparentWhite.png";
import MetroIcon from "../../media/icons/m.png";
import Metro13Icon from "../../media/icons/13.png";
import Metro14Icon from "../../media/icons/14.png";
import BusIcon from "../../media/icons/bus.png";
import Bus341Icon from "../../media/icons/341.png";
import RerIcon from "../../media/icons/rerIcon.png";
import RerCIcon from "../../media/icons/rerC.png";
import TramIcon from "../../media/icons/TramIcon.png";
import T3bIcon from "../../media/icons/T3b.png";
import pdffile from "../../media/e-book/Guide parents_Wyze Kids.pdf";
import toast from "react-hot-toast";


const Footer = () => {

    const [email, setEmail] = useState("");
    const [isSubmitted, setIsSubmitted] = useState(false); // New state to track submission success
    const [message, setMessage] = useState("");
    const [showMessage, setShowMessage] = useState(false); // State to control visibility
    const emailRef = useRef(null);
    // const [notification, setNotification] = useState({ type: "", message: "" });
    // const clearNotification = () => {
    //     setTimeout(() => {
    //         setNotification({ type: "", message: "" });
    //     }, 5000);
    // };

    const baseUrl =  "https://server1.wyze-kids.com";//"http://localhost:3001";


    // const handleClick = (e) => {
    //     e.preventDefault(); // Prevents the form from submitting
    //     setMessage("Non disponible pour le moment.");
    //     setShowMessage(true); // Show the message
    //     // Hide the message after 4 seconds (4000 milliseconds)
    //     setTimeout(() => {
    //         setShowMessage(false);
    //     }, 4000);
    // };


    const handleSubmit = async (event) => {
        event.preventDefault();
        let errorMessage = "";

        if (!email) {
            errorMessage = "Veuillez entrer votre email.";
            emailRef.current.focus();
        }

        if (errorMessage) {
            console.log(errorMessage);
            return;
        }

        try {
            const res = await fetch(`${baseUrl}/ebookEmail`, {
                method: "POST",
                body: JSON.stringify({ email }),
                headers: {
                    "Content-Type": "application/json",
                },
            });

            if (res.ok) {
                setEmail(""); // Clear email input
                setIsSubmitted(true); // Set the state to indicate success
                console.log("Email sent successfully");
            } else {
                const data = await res.json();
                console.error("Server error response:", data.message);
            }
        } catch (error) {
            console.error("Fetch request error: ", error.message);
        }
    };

    const handlePdfDownload =  () => {
        // Get the pdf file
        const pdfUrl = pdffile
        // Create a new anchor element
        const link = document.createElement('a');
        // Set the href and download attributes for the anchor element
        link.href = pdfUrl;
        // set the download attribute to download the pdf
        link.setAttribute('download', 'Guide parents_Wyze Kids.pdf');
        // Append the anchor element to the body
        document.body.appendChild(link);
        link.click()
        // Remove the anchor element from the body
        document.body.removeChild(link);
        toast.success('Ebook téléchargé avec succès');

    }

  return (
    <div id="footer" className="footer-wrapper">
      <div className="footer-top1"></div>
      <div className="footer-top2"></div>
      <div className="footer-container">

        <div className="footer-body">
            <div className="ebook-container">
                <p>E-book</p>
                {isSubmitted ?( <h6>Téléchargez notre e-book</h6>) : (
                    <h6> pour obtenir votre ebook<br/> veuillez renseigner votre email</h6>
                )}
                <div>
                    {isSubmitted ? (
                        // Show download button if the form is successfully submitted
                        <>
                            <button className="btn-telecharge" type="submit" onClick={handlePdfDownload}>
                                Télécharger
                            </button>
                            {message && <div className={`message-container ${showMessage ? 'show' : 'hide'}`}>
                                <span className="message">{message}</span>
                            </div>}
                        </>

                    ) : (
                        // Show form if the email has not been submitted
                        <form className="ebook-form" onSubmit={handleSubmit}>
                            <div className="ebook-email-container">
                                <input
                                    name="email"
                                    type="email"
                                    id="email"
                                    ref={emailRef}
                                    value={email}
                                    onChange={(e) => setEmail(e.target.value)}
                                    autoComplete="false"
                                    required
                                    placeholder="Votre email"
                                />
                                <button type="submit">Envoyer</button>
                            </div>
                        </form>
                    )}
                </div>
            </div>
            <div className="social-networks-container">
                <h4 className="social-networks-title semi-bold">Suivez-nous</h4>
                <div className="social-networks-icons-container">
                <a
                href="https://web.facebook.com/profile.php?id=61561282500074"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Facebook"
              >
                <FaFacebook
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.instagram.com/wyze.kids/"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Instagram"
              >
                <FaInstagram
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.linkedin.com/company/academy-wyze/?viewAsMember=true"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre page Linkedin"
              >
                <FaLinkedin
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.youtube.com/@WyzeAcademy"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre chaine Youtube"
              >
                <FaYoutube
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://www.tiktok.com/@wyzekids"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien vers notre compte Tiktok"
              >
                <FaTiktok
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
              <a
                href="https://wa.me/0745628826"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Lien pour nous contacter via Whatsapp"
              >
                <FaWhatsapp
                  style={{
                    cursor: "pointer",
                    width: "25px",
                    height: "100%",
                    color: "white",
                  }}
                />
              </a>
            </div>
          </div>



          <div className="info-container">
            <h4 className="info-title semi-bold">Informations pratiques</h4>
              <div className="info-body">
                  <Link
                      to="/mentions-legales"
                      className="info-link"
                      aria-label="Lien vers la page des mentions légales"
                  >
                      <p className="info-text">Mentions légales</p>
                  </Link>

                  <Link
                      to="/mentions-legales/accessibilite"
                      className="info-link"
                      id="footer-accessibility-link"
                      aria-label="Lien vers la page d'accessibilité"
                  >
                      <div className="accessibility-link-container">
                          <BiHandicap
                              style={{
                                  cursor: "pointer",
                                  width: "25px",
                                  height: "100%",
                                  color: "white",
                              }}
                          />
                          <p className="info-text">Accessibilité</p>
                      </div>
                  </Link>

                  {/* <Link
                to="/#contact-section"
                className="info-link"
                id="contact-section-footer-link">
                </Link> */}
                  <a
                      href="/#contact-section"
                      className="info-link"
                      rel="noopener noreferrer"
                  >
                      <p className="info-text">Nous contacter</p>
                  </a>
                  <div className="plan-container">
                      <h4 className="info-link-plan">Plan du site</h4>
                      <div className="plan-body">
                          <div className="plan-column">
                              <a
                                  href="/construction-progress?title=Kids"
                                  className="plan-link"
                                  aria-label="Lien vers la page avec les formations pour les enfants"
                              >
                                  <p className="plan-text">Kids</p>
                              </a>
                          </div>
                          <div className="plan-column">
                              <a
                                  href="/construction-progress?title=Ados"
                                  className="plan-link"
                                  aria-label="Lien vers la page avec les formations pour les adolescents"
                              >
                                  <p className="plan-text">Adolescents</p>
                              </a>
                          </div>
                      </div>
                  </div>

                  {/* <a
                href="https://g.co/kgs/g28ddXF"
                target="_blank"
                rel="noopener noreferrer"
                className="info-link"
              >
                <p className="info-text">Nous retrouver</p>
              </a> */}
              </div>
              <p className="footer-text footer-adress-responsive">
                  Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
              </p>
          </div>

            <div className="transport-container">
                <h4 className="transport-title semi-bold">Transports</h4>
                <div className="transport-body">
                    <div className="transport-section">
                        <div className="transport-description">
                            <img
                                src={MetroIcon} alt="Metro"
                                // width={"25px"}
                                className="footer-transport-icon"
                            />
                            <img
                                src={Metro13Icon} alt="Metro13"
                                // width={"30px"}
                                className="footer-transport-icon"
                            />
                            <p className="transport-text">Garibaldi</p>
                        </div>
                        <div className="transport-description">
                  <img
                    src={MetroIcon} alt="MetroIcon"
                    // width={"25px"}
                    className="footer-transport-icon"
                  />
                  <img
                    src={Metro14Icon} alt="Metro14Icon"
                    // width={"30px"}
                    className="footer-transport-icon"
                  />
                  <p className="transport-text">Saint-Ouen</p>
                </div>
                <div className="transport-description">
                  <img
                    src={BusIcon} alt="BusIcon"
                    className="footer-transport-icon"
                    // style={{ maxWidth: "25px", objectFit: "contain" }}
                  />
                  <img
                    src={Bus341Icon} alt="Bus341Icon"
                    className="footer-transport-icon"
                    // style={{ maxWidth: "30px", objectFit: "contain" }}
                  />
                  <p className="transport-text">Toulouse Lautrec</p>
                </div>
                <div className="transport-description">
                  <img
                    src={RerIcon} alt="RerIcon"
                    // width={"25px"}
                    className="footer-transport-icon"
                  />
                  <img
                    src={RerCIcon} alt="RerCIcon"
                    // width={"25px"}
                    className="footer-transport-icon"
                  />

                  <p className="transport-text">Saint-Ouen</p>
                </div>
                <div className="transport-description">
                  <img
                    src={TramIcon} alt="TramIcon"
                    // width={"25px"}
                    className="footer-transport-icon"
                  />

                  <img
                    src={T3bIcon} alt="T3bIcon"
                    // width={"30px"}
                    className="footer-transport-icon"
                  />
                  <p className="transport-text">Porte de Saint-Ouen</p>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="footer-footer">
          <a href={"/#"}>
            <img src={logo} alt="Logo Wyze" className="logo-footer-image" />
          </a>
          <p className="footer-text footer-adress">
            Adresse : 60 Av. du Capitaine Glarner, 93400 Saint-Ouen-sur-Seine
          </p>
          <p className="footer-text">© 2024 Wyze Academy</p>
        </div>
      </div>
    </div>
  );
};

export default Footer;
