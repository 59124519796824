import React, {useEffect, useRef, useState} from 'react';
import { useForm, Controller } from 'react-hook-form';
import Select from 'react-select';
import countryList from 'react-select-country-list';
import DOMPurify from 'dompurify';
import PhoneInput from 'react-phone-number-input';
import payPalImage from "../validation-image/paypal.png";
import { PayPalButtons, usePayPalScriptReducer } from "@paypal/react-paypal-js";
import {useLocation} from "react-router-dom";
import axios from "axios";


function ValidationForm() {

    const tva = 20;
    const location = useLocation();
    const { cart, total } = location.state || { cart: [], total: 0 };
    //const [sdkReady, setSdkReady] = useState(false)

    const [formData, setFormData] = useState(null);
    const [isPayPalDisabled, setIsPayPalDisabled] = useState(true);
    const [isValid, setIsValid] = useState(false);

    const formRef = useRef(null);
    const { register,
        handleSubmit,
        control,watch,
        formState: { errors },
        setError,
        clearErrors,
        reset,
        trigger
    } = useForm({mode:"onChange"});


    const [{ isPending }, dispatch] = usePayPalScriptReducer(); // Correct hook usage
    // const [currency, setCurrency] = useState(options.currency);

    const countryOptions = countryList().getData();
    const [isFocused, setIsFocused] = useState(false);


    const [courseDetails, setCourseDetails] = useState(
        {
            reference: "1",
            name: "Coder niveau 1",
            courseType: "digital", // digital, physical
            unitPrice: total,
            taxRate: 0,
            discountRate: 0,
        }
    );


    const handleFormSubmit = async (data) => {
        const sanitizedData = {
            prenom: DOMPurify.sanitize(data.prenom),
            nom: DOMPurify.sanitize(data.nom),
            pays: data.pays ? DOMPurify.sanitize(data.pays.label) : '',
            rue: DOMPurify.sanitize(data.rue),
            postal: DOMPurify.sanitize(data.postal),
            ville: DOMPurify.sanitize(data.ville),
            tel: DOMPurify.sanitize(data.tel),
            email: DOMPurify.sanitize(data.email),
        };
        setFormData(sanitizedData);
        const valid = await trigger();
        setIsValid(valid);
    };

    const onCreateOrder = (data, actions) => {
        return actions.order.create({
            purchase_units: [
                {
                    amount: {
                        value: courseDetails.unitPrice,
                    },
                },
            ],
        });
    };
   
    const onApproveOrder = (data, actions) => {
        return actions.order.capture().then(async (details) => {
            const name = details.payer.name.given_name;
            alert(`Transaction completed by ${name}`);

            // Combine formData and payment details
            const combinedData = {
                ...formData,
                paymentDetails: details
            };

            // Send combined data to our  backend API for saving to the database only
            try {
                const response = await fetch('https://server1.wyze-kids.com', {
                    method: 'POST',
                    headers: {
                        'Content-Type': 'application/json'
                    },
                    body: JSON.stringify(combinedData)
                });

                if (!response.ok) {
                    throw new Error('Network response was not ok');
                }

                const responseData = await response.json();
                console.log('Data successfully registered:', responseData);
                reset();
                setIsPayPalDisabled(true);
            } catch (error) {
                console.error('Error registering data:', error);
            }
        });
    };

    const handlePayPalClick = async (data, actions) => {
        const valid = await trigger();
        setIsValid(valid);
        if (!valid) {
            setIsPayPalDisabled(true);
            console.log("Form is invalid");
            return actions.reject();
        } else {
            setIsPayPalDisabled(false);
            await handleSubmit(handleFormSubmit)();
            return onCreateOrder(data, actions);

        }
    };

    useEffect(() => {
        const subscription = watch(async () => {
            const isValidForm = await trigger();
            setIsPayPalDisabled(!isValidForm);
        });
        return () => subscription.unsubscribe();
    }, [watch, trigger]);



    return (
        <div>
            <form className="form">
                <div className="content-container">
                    <div  className="info share shadow">
                        <h2 className="form-title">Détails De Facturation</h2>
                        <div className="form-content">
                            <div className="input-layout">
                                <div className="input-container">
                                    <label className="label"
                                           htmlFor="prenom">
                                        Prénom<span className="star">*</span>
                                    </label>
                                    <input type="text"
                                           id="prenom"
                                           {...register('prenom', {required: 'Prénom est requis'})}
                                        // style={{ borderColor: errors.prenom ? 'red' : '#ced4da', borderWidth: '1px', borderStyle: 'solid' }}
                                           placeholder="Joe"/>
                                    {errors.prenom && <span style={{color: 'red'}}>{errors.prenom.message}</span>}
                                </div>
                                <div className="input-container">

                                    <label className="label"
                                           htmlFor="prenom">
                                        Nom<span className="star">*</span>
                                    </label>
                                    <input type="text"
                                           id="nom"
                                           {...register('nom', {required: 'Nom est requis'})}
                                           placeholder="Shmoe"/>
                                    {errors.nom && <span style={{color: 'red'}}>{errors.nom.message}</span>}
                                </div>
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="pays">
                                    Pays/region<span className="star">*</span>
                                </label>
                                <Controller
                                    name="pays"
                                    control={control}
                                    rules={{required: 'Pays/region est requis'}}
                                    render={({field}) => (
                                        <>
                                            <Select
                                                {...field}
                                                options={countryOptions}
                                                placeholder="Sélectionnez votre pays"
                                                styles={{
                                                    control: (baseStyles) => ({
                                                        ...baseStyles,
                                                        height: '47px',
                                                        borderColor: baseStyles.borderColor,
                                                    }),
                                                }}
                                                theme={(theme) => ({
                                                    ...theme,
                                                    borderRadius: "6px",
                                                    colors: {
                                                        ...theme.colors,
                                                        primary25: 'hsl(0, 0%, 80%)',
                                                        primary: '#00213B',
                                                    },
                                                })}
                                                onChange={(value) => {
                                                    if (value) {
                                                        clearErrors('pays');
                                                        field.onChange(value);
                                                    } else {
                                                        setError('pays', {
                                                            type: 'required',
                                                            message: 'Pays/region est requis',
                                                        });
                                                        field.onChange(null); // Set to null if no value selected
                                                    }
                                                }}
                                                value={field.value || null}
                                            />
                                            {errors.pays && <span style={{color: 'red'}}>{errors.pays.message}</span>}
                                        </>
                                    )}
                                />
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="rue">
                                    Numero et nom de rue<span className="star">*</span>
                                </label>
                                <input type="text"
                                       id="pays"
                                       {...register('rue', {required: 'Numéro et nom de rue est requis'})}
                                       placeholder="11 bd baille"
                                />
                                {errors.rue && <span style={{color: 'red'}}>{errors.rue.message}</span>}
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="postal">
                                    Code postal<span className="star">*</span>
                                </label>
                                <input type="text"
                                       id="pays"
                                       {...register('postal', {required: 'Code postal est requis'})}
                                       placeholder="20023"
                                />
                                {errors.postal && <span style={{color: 'red'}}>{errors.postal.message}</span>}
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="ville">
                                    Ville<span className="star">*</span>
                                </label>
                                <input type="text"
                                       id="pays"
                                       {...register('ville', {required: 'Ville est requise'})}
                                       placeholder="Paris"
                                />
                                {errors.ville && <span style={{color: 'red'}}>{errors.ville.message}</span>}
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="tel">
                                    Téléphone<span className="star">*</span>
                                </label>
                                <Controller
                                    name="tel"
                                    control={control}
                                    rules={{
                                        required: 'Téléphone est requis',
                                        validate: value => value && value.length >= 10 || 'Le numéro de téléphone est incomplet',
                                    }}
                                    render={({field, fieldState}) => (
                                        <PhoneInput
                                            {...field}
                                            defaultCountry="FR"
                                            placeholder="07 52 36 95"
                                            style={{
                                                border: isFocused ? '2px solid black' : '1px solid hsl(0, 0%, 80%)',
                                                outline: 'black',
                                            }}
                                            onFocus={() => setIsFocused(true)}
                                            onBlur={() => setIsFocused(false)}
                                        />
                                    )}
                                />
                                {errors.tel && <span style={{color: 'red'}}>{errors.tel.message}</span>}
                            </div>

                            <div className="input-container">
                                <label className="label"
                                       htmlFor="email">
                                    E-mail<span className="star">*</span>
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    {...register('email', {required: 'E-mail est requis'})}
                                    placeholder="joeshmoe@yahoo.com"
                                />
                                {errors.email && <span style={{color: 'red'}}>{errors.email.message}</span>}
                            </div>

                        </div>
                    </div>

                    <div className="commande share shadow">
                        <div>
                            <h2 className="commande-title no-wrap">Validation Commande</h2>
                            <div className="flex">
                                <h3>Formation</h3>
                                <h3>Sous-Total</h3>
                            </div>
                            <div className="divider"></div>
                            {cart.map((cours)=> (
                                <div key={cours.course_reference} className="flex space">
                                    <div className="text-container">
                                        <p className="text text-title">{cours.formation_title} x {cours.nbr_enfant} </p>
                                        <p className="text">FORMATION : {cours.formation_title}</p>
                                        <p className="text">DATE : {cours.date_choisi}</p>
                                        <p className="text">FORMATION PRIX / ENFANT : {cours.formation_prix}€</p>
                                    </div>
                                    <div><span className="total">{cours.formation_prix * cours.nbr_enfant - (cours.formation_prix * cours.nbr_enfant * tva / 100) }€</span></div>
                                </div>
                            ))}

                            <div className="divider"></div>

                            <div className="flex">
                                <p className="sous-total">Sous-total</p>
                                <p className="sous-total">{total - (total * tva / 100)}€</p>
                            </div>
                            <div className="divider"></div>

                            <div className="flex">
                                <p className="sous-total">TVA</p>
                                <p className="sous-total">{(total * tva / 100)}€</p>
                            </div>
                            <div className="divider"></div>

                            <div className="flex">
                                <p className="sous-total">Total</p>
                                <p className="sous-total">{total - (total * tva / 100) + (total * tva / 100)}€</p>
                            </div>
                            <div className="divider"></div>
                        </div>

                        <div className="flex-column">
                            {!isPayPalDisabled ? (
                                <PayPalButtons
                                    style={{
                                        layout: "vertical",
                                        height: 50,
                                        borderRadius: 10,
                                    }}
                                    createOrder={handlePayPalClick}
                                    onApprove={(data, actions) => onApproveOrder(data, actions)}
                                    onError={(err) => {
                                        console.error("PayPal Buttons Error:", err);
                                    }}
                                />
                            ) : (

                                <button className="btn"
                                        type="button"
                                        disabled={true}
                                        style={{
                                            height: 50,
                                            borderRadius: 10,
                                            backgroundColor: "#e0e0e0", // Grey background for disabled look
                                            cursor: "not-allowed",
                                        }}
                                >
                                    <img className="paypal"
                                         src={payPalImage}
                                         alt="paypal image"/>
                                </button>
                            )}
                        </div>
                    </div>
                </div>

            </form>
        </div>
    );
}

export default ValidationForm;