import "./KidsPage.css";
import { Helmet } from "react-helmet";
import HeroSection from "./kids-sections/hero-sections/HeroSection";
import DiscoverSection from "./kids-sections/discover-section/DiscoverSection";
// import AtelierSection from "./kids-sections/atelier-section/AtelierSection";
import AtelierSection from "./kids-sections/ateliers-section-2/AtelierSection";
function KidsPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wyze Academy</title>
        <meta
          name="description"
          content="Formations numériques pour enfants, courtes ou longues, distanciel ou présentiel, éligibles CPF, certifiantes et micro-certifiantes"
        />
        <meta
          name="keywords"
          content="formations courtes, formations longues, adultes, en présentiel, en distanciel"
        />
      </Helmet>
      <div className="kids-page-wrapper">
        <HeroSection />
        <DiscoverSection />
        <AtelierSection />
      </div>
    </>
  );
}

export default KidsPage;
