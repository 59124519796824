export const ateliers = [
    {
        id:1,
        image: "../../../../media/images/atelier_image_2.png",
        title:'Initiation à la Programmation',
        desccription:'Apprenez les bases de la programmation à travers des projets amusants et créatifs. Développez votre logique et votre créativité.',

    },
    {
        id:2,
        image:"../../../../media/images/atelier_image_1.png",
        title:'Initiation à la Programmation',
        desccription:'Apprenez les bases de la programmation à travers des projets amusants et créatifs. Développez votre logique et votre créativité.',

    }
]