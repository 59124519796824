import React, { useEffect, useState } from "react";
import Strie from "../../../../components/Strie/Strie";
import testHeader1Image from "../../../../media/images/formations_images/initiation_a_la_programmation_niveau_1_header_image.png";
import testHeader2Image from "../../../../media/images/formations_images/initiation_a_la_programmation_niveau_2_header_image.png";
import testHeader3Image from "../../../../media/images/formations_images/initiation_a_la_programmation_niveau_3_header_image.png";
import WyzeLogo from "../../../../media/images/logoKidsTransparentWhite.png";
import "./AdoFormationInfoDetails.css";

import testFooter1ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_1_footer_image-1.png";
import testHeader1ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_1_header_image.png";
import testFooter2ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_2_footer_image-1.png";
import testHeader2ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_2_header_image.png";
import testFooter3ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_3_footer_image-1.png";
import testHeader3ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_3_header_image.png";
import testFooter4ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_4_footer_image-1-removebg-preview.webp";
import testHeader4ImageDistance from "../../../../media/images/formations_images/initiation_a_la_programmation_distance_niveau_4_header_image.png.webp";

import testFooter2Image from "../../../../media/images/formations-ado-presentiel-card-niveau-5-2.png";
import testFooter3Image from "../../../../media/images/formations-ado-presentiel-card-niveau-5-3.png";
import testFooter1Image from "../../../../media/images/formations-ados-presentiel-card-niveau-5-1.png";

import FormationsDistancielData from "./FormationsDistancielData.json";

import distenciel1 from "../../../../media/brochures/brochure_coder_niveau_1_distanciel.pdf";
import presentiel1 from "../../../../media/brochures/brochure_coder_niveau_1_presentiel.pdf";
import distenciel2 from "../../../../media/brochures/brochure_coder_niveau_2_distanciel.pdf";
import presentiel2 from "../../../../media/brochures/brochure_coder_niveau_2_presentiel.pdf";
import distenciel3 from "../../../../media/brochures/brochure_coder_niveau_3_distanciel.pdf";
import presentiel3 from "../../../../media/brochures/brochure_coder_niveau_3_presentiel.pdf";

import presentielVaccance from "../../../../media/brochures/brochure_coder_niveau_2_presentiel_vaccance.pdf";

const getPDF = (formationTitle, formationType, formationPeriod) => {
  if (formationType.toLowerCase() === "distanciel") {
    switch (formationTitle.toLowerCase()) {
      case "coder niveau 1":
        return distenciel1;
      case "coder niveau 2":
        return distenciel2;
      case "coder niveau 3":
        return distenciel3;
      default:
        return null;
    }
  } else if (formationType.toLowerCase() === "presentiel") {
    if (formationPeriod === "vacances scolaires") {
      return presentielVaccance;
    }
    switch (formationTitle.toLowerCase()) {
      case "coder niveau 1":
        return presentiel1;
      case "coder niveau 2":
        return presentiel2;
      case "coder niveau 3":
        return presentiel3;
      default:
        return null;
    }
  }
  return null;
};

const handleDownload = (formationTitle, formationType, formationPeriod) => {
  const pdfFile = getPDF(formationTitle, formationType, formationPeriod);

  if (!pdfFile) {
    alert("PDF not found for the selected formation!");
    return;
  }

  const link = document.createElement("a");
  link.href = pdfFile;
  link.setAttribute("download", `${formationTitle}_${formationType}.pdf`);
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

function AdoFormationInfo({ formation, type }) {
  const [imageHeaderFormation, setImageHeaderFormation] = useState(null);
  const [imageFooterFormation, setImageFooterFormation] = useState(null);
  const getImage = async (formationTitle, type) => {
    const fileName = formationTitle
      .replace(/\s+/g, "_")
      .toLowerCase()
      .replace(/é/g, "e")
      .replace(/à/g, "a");

    try {
      const imageModule = await import(
        `../../../../media/images/formations_images/${fileName}_${type}_image.png`
      );
      return imageModule.default;
    } catch (error) {
      //   console.error("Error loading image:", error);
      return null;
    }
  };

  useEffect(() => {
    const loadImage = async () => {
      //   if (formation.formation_type === "longue") {
      const src = await getImage(formation.formation_title, "header");
      setImageHeaderFormation(src);
      //   }
    };

    loadImage();
  });

  useEffect(() => {
    const loadImage = async () => {
      //   if (formation.formation_type === "longue") {
      const src = await getImage(formation.formation_title, "footer");
      setImageFooterFormation(src);
      //   }
    };

    loadImage();
  });

  if (type === FormationsDistancielData) {
    return (
      <div>
        <div
          className={`ado-formation-container vacances-scolaires ${
            formation?.formation_title === "Stage 14-18 ans"
              ? "numerique-14-18"
              : ""
          }`}
        >
          <div className="ado-formation-header">
            <img
              className="ado-formation-header-image"
              src={
                formation?.formation_title === "Stage 14-18 ans"
                  ? testHeader4ImageDistance
                  : formation?.formation_title === "Coder niveau 3"
                  ? testHeader3ImageDistance
                  : formation?.formation_title === "Coder niveau 2"
                  ? testHeader2ImageDistance
                  : testHeader1ImageDistance
              }
              alt="header formation"
            />
            <div className="ado-formation-header-left">
              <img
                className="logo-img"
                src={WyzeLogo}
                width={"50%"}
                alt="Wyze logo"
              />
              <h1 className="ado-formation-span-formation">
                {formation?.title}
              </h1>
              {formation?.formation_title && (
                <h1 className="ado-formation-header-title">
                  {formation?.formation_title}
                </h1>
              )}
            </div>
          </div>
          <div className="ado-formation-body">
            <div className="ado-formation-info">
              {formation?.formation_h3?.[0] &&
                formation?.spanh3?.[0] &&
                [1, 2, 3, 4].map((num) => (
                  <div key={num}>
                    <p>{formation.formation_h3[0][`numéro${num}`]}:</p>
                    <span>{formation.spanh3[0][`span${num}`]}</span>
                  </div>
                ))}
            </div>

            <div className="ado-formation-competences">
              {formation?.formation_title1 && (
                <h1 className="ado-formation-competences-title">
                  {formation?.formation_title1}
                </h1>
              )}
              {formation?.formation_modules?.map((module, idx) => (
                <div key={idx} className="ado-formation-competence-container">
                  <h2 className="ado-formation-competence-title">
                    {module?.titre}
                  </h2>
                  <ul className="ado-formation-competence-list">
                    {module?.sous_modules?.map((sous_module, idx) => (
                      <li
                        key={idx}
                        className="ado-formation-competence-list-item"
                      >
                        {sous_module}
                      </li>
                    ))}
                  </ul>
                </div>
              ))}
            </div>
            <div className="ado-formation-price">
              <div className="ado-formation-price-right">
                <h1>Prix :</h1>
                <h1>{formation?.formation_prix?.toLocaleString("fr-FR")} €</h1>
                <ul className="ado-formation-price-list">
                  <li>Paiement jusqu'à 4 fois sans frais</li>
                </ul>
              </div>
              <img
                className="ado-formation-footer-image"
                src={
                  formation?.formation_title === "Stage 14-18 ans"
                    ? testFooter4ImageDistance
                    : formation?.formation_title === "Coder niveau 3"
                    ? testFooter3ImageDistance
                    : formation?.formation_title === "Coder niveau 2"
                    ? testFooter2ImageDistance
                    : testFooter1ImageDistance
                }
                alt="footer formation"
              />
            </div>
            <div className="ado-formation-footer">
              <a
                href="tel:+33 7 45 62 88 26"
                className="ado-formation-contact-button"
              >
                Contacter
              </a>
              <button
                className="ado-formation-download-button"
                onClick={() =>
                  handleDownload(
                    formation?.formation_title,
                    formation?.formation_type
                  )
                }
              >
                Télécharger le programme
              </button>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div>
      <div
        className={`ado-formation-container vacances-scolaires ${
          formation?.formation_title === "Stage 14-18 ans"
            ? "numerique-14-18"
            : ""
        }`}
      >
        <div className="ado-formation-header">
          <img
            className="ado-formation-header-image"
            src={
              formation?.formation_title === "Stage 14-18 ans"
                ? testHeader4ImageDistance
                : formation?.formation_title === "Coder niveau 3"
                ? testHeader3Image
                : formation?.formation_title === "Coder niveau 2"
                ? testHeader2Image
                : testHeader1Image
            }
            alt="header formation"
          />
          <div className="ado-formation-header-left">
            <img
              className="logo-img"
              src={WyzeLogo}
              width={"50%"}
              alt="Wyze logo"
            />
            <h1 className="ado-formation-span-formation">{formation?.title}</h1>
            {formation?.formation_title && (
              <h1 className="ado-formation-header-title">
                {formation?.formation_title}
              </h1>
            )}
          </div>
        </div>
        <div className="ado-formation-body">
          <div className="ado-formation-info">
            {formation?.formation_h3?.[0] &&
              formation?.spanh3?.[0] &&
              [1, 2, 3, 4].map((num) => (
                <div key={num}>
                  <p>
                    <strong>
                      {" "}
                      {formation.formation_h3[0][`numéro${num}`]}:
                    </strong>
                  </p>
                  <span>{formation.spanh3[0][`span${num}`]}</span>
                </div>
              ))}
          <Strie className="strie-info" scale={3} zindex={1} top="68%" left="21px"  />
          </div>

          <div className="ado-formation-competences">
            {formation?.formation_title1 && (
              <h1 className="ado-formation-competences-title">
                {formation?.formation_title1}
              </h1>
            )}
            {formation?.formation_modules?.map((module, idx) => (
              <div key={idx} className="ado-formation-competence-container">
                <h2 className="ado-formation-competence-title">
                  {module?.titre}
                </h2>
                <ul className="ado-formation-competence-list">
                  {module?.sous_modules?.map((sous_module, idx) => (
                    <li
                      key={idx}
                      className="ado-formation-competence-list-item"
                    >
                      {sous_module}
                    </li>
                  ))}
                </ul>
              </div>
            ))}
          </div>
          <div className="ado-formation-price">
            <div className="ado-formation-price-right">
              <h1> </h1>
              <h1>{formation?.formation_prix?.toLocaleString("fr-FR")} €</h1>
              <ul className="ado-formation-price-list">
                <li>Paiement jusqu'à 4 fois sans frais</li>
              </ul>
            </div>
            <Strie className="strie-info" scale={3} zindex={1} top="26%" left="-31px"  />

            <img
              className="ado-formation-footer-image"
              src={
                formation?.formation_title === "Stage 14-18 ans"
                  ? testFooter4ImageDistance
                  : formation?.formation_title === "Coder niveau 3"
                  ? testFooter3Image
                  : formation?.formation_title === "Coder niveau 2"
                  ? testFooter2Image
                  : testFooter1Image
              }
              alt="footer formation"
            />
          </div>
          <div className="ado-formation-footer">
            <a
              href="tel:+33 7 45 62 88 26"
              className="ado-formation-contact-button"
            >
             Contact
            </a>

            <button
              className="ado-formation-download-button"
              onClick={() =>
                handleDownload(
                  formation?.formation_title,
                  formation?.formation_type,
                  formation?.formation_period
                )
              }
            >
              Télécharger le programme
            </button>
          </div>
        </div>
      </div>
    </div>
  );
}

export default AdoFormationInfo;
