import React, { useState, useEffect } from "react";
import "./Cookie.css";
import CookieIcon from "../../media/icons/cookie_icon_1.png";
import Strie from "../Strie/Strie";

const CookieConsent = () => {
  const [cookieAccepted, setCookieAccepted] = useState(false);

  useEffect(() => {
    console.log("Le composant CookieConsent est monté.");

    // Vérification si les cookies ont déjà été acceptés lors des visites précédentes
    const isCookieAccepted = localStorage.getItem("cookieAccepted");

    // Si les cookies ont déjà été acceptés, ne pas afficher le composant
    if (isCookieAccepted === "true") {
      setCookieAccepted(true);
    } else if (isCookieAccepted === "false") {
      // Si les cookies ont été refusés lors des visites précédentes, afficher le composant pour cette visite
      console.log(
        "Les cookies ont été refusés lors de la visite précédente. CookieConsent sera affiché pour cette visite."
      );
    }
  }, []);

  const handleAccept = () => {
    console.log("L'utilisateur a accepté les cookies.");

    // Enregistrer la décision de l'utilisateur dans le localStorage
    localStorage.setItem("cookieAccepted", true);
    setCookieAccepted(true);

    console.log(
      "Contenu actuel du localStorage:",
      JSON.stringify(localStorage, null, 2)
    );
  };

  const handleReject = () => {
    console.log("L'utilisateur a refusé le suivi des cookies.");

    // Enregistrer la décision de l'utilisateur dans le localStorage
    localStorage.setItem("cookieAccepted", false);

    console.log(
      "Contenu actuel du localStorage:",
      JSON.stringify(localStorage, null, 2)
    );

    // Ne pas afficher le composant pour cette visite
    setCookieAccepted(true);

    // Afficher le composant lors de la prochaine visite
    console.log(
      "Vous avez refusé le suivi des cookies. Le composant CookieConsent sera affiché lors de votre prochaine visite."
    );

    // alert(
    //   "Vous avez refusé le suivi des cookies. Le composant CookieConsent sera affiché lors de votre prochaine visite."
    // );
  };

  // Ne pas afficher le composant si les cookies sont acceptés ou si l'utilisateur a refusé les cookies lors de la visite actuelle
  if (cookieAccepted) {
    return null;
  }

  // Rendre le composant CookieConsent pour demander l'acceptation ou le refus des cookies
  return (
    <div className="cookie-consent">
      <Strie scale={1.5} left="90%" zindex={-1} />
      <Strie scale={1} top="-15%" left="86%" zindex={-1} />
      {/* <Strie color="blue" scale={3} top="135%" left="-25%" zindex={-1} /> */}
      <div className="cookie-header">
        <img className="cookie-icon" src={CookieIcon} alt="cookie" />
        <div className="cookie-header-title">
          <h4 className="semi-bold">Cookies</h4>
          <a href="/mentions-legales" className="cookie-mentions-legales">
            <p className="cookie-mentions-legales-text">
              Lire les mentions légales
            </p>
          </a>
        </div>
      </div>

      <p>
        Ce site utilise des cookies. <br /> Acceptez-vous le suivi des cookies ?
      </p>
      <div className="cookie-footer">
        <button className="cookie-secondary-button" onClick={handleReject}>
          Refuser
        </button>
        <button className="cookie-primary-button" onClick={handleAccept}>
          J'accepte
        </button>
      </div>
      {/* 
      <Link to="/PolitiqueConfidentialite">
        <button>Voir la Politique de Confidentialité</button>
      </Link> */}
    </div>
  );
};

export default CookieConsent;
