import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import "./Navbar.css";
import { FaBars, FaTimes } from "react-icons/fa";
import ScrollToHashElement from "./ScrollToHashElement.jsx";
import logo from "../../media/images/logoKidsTransparentWhite.png";
// import logo1 from "../../media/images/logo_white_wyze_croped.png";
// import logo2 from "../../media/images/wyze_kids_logo_cropped_3.png";
import Strie from "../../components/Strie/Strie.jsx";
import { PiShoppingCartBold } from "react-icons/pi";
import secureLocalStorage from "react-secure-storage";
import {useGlobalContext} from "../../context";



export default function Navbar() {
  const [isHidden, setIsHidden] = useState(false); // state for hidden the navbar
  const [isOpen, setIsOpen] = useState(false); // State for mobile menu toggle

  // const [cart, setCart] = React.useState(
  //
  //     secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : []
  //
  // )

 const{cart,updateCart} = useGlobalContext()


  const numOfFormation = cart.length

  const handleScroll = () => {
    window.scrollY > 800 ? setIsHidden(true) : setIsHidden(false);
    // Hide navbar after scrolling past 800px
  };

  useEffect(() => {
    /** */
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  useEffect(() => {
    if (isOpen) {
      document.body.style.overflow = "hidden";
    } else {
      document.body.style.overflow = "auto";
    }

    return () => {
      document.body.style.overflow = "auto";
    };
  }, [isOpen]);

  const OpenMenu = () => {
    setIsOpen(true);
  };

  const CloseMenu = () => {
    setIsOpen(false);
  };

  return (
    <nav className={isHidden ? "navbar-hidden" : "navbar"}>
      <ScrollToHashElement />
      <div className="logo-btn-box">
        <div className="logo">
          <Link to="/">
            <img src={logo} alt="Logo Wyze" className="logoImageNavbar" />
          </Link>
        </div>
        <button className="nav-btn-menu-burger " onClick={OpenMenu}>
          <FaBars />
        </button>
      </div>

      <div className={`nav-menu ${isOpen ? "active" : ""}`}>
        <div className="nav-menu-strie-container">
          <Strie scale={3} left="90%" top="3%" />
          <Strie scale={1.5} left="85%" top="0%" />
          <Strie scale={7} color="blue" left="40%" top="93%" />
          <Strie scale={3} color="blue" left="15%" top="96%" />
        </div>
        <button className="btn-close-menu" onClick={CloseMenu}>
          <FaTimes />
        </button>
        <div className="nav-menu-li">
          <Link
            className={isOpen ? "nv-li" : "nv-li welcome"}
            to="/"
            onClick={CloseMenu}
          >
            Accueil
          </Link>

          <Link className="nv-li" to="/#formation-section" onClick={CloseMenu}>
            Nos formations
          </Link>
          <Link className="nv-li" to="/#faq-section" onClick={CloseMenu}>
            FAQ
          </Link>
          <Link
            className="nv-li"
            // activeClass="active"
            to="/#contact-section"
            onClick={CloseMenu}
          >
            Contact
          </Link>
            <Link className="nv-li" to="/#footer" onClick={CloseMenu}>
                E-book
            </Link>
          <Link className="nv-li cart-icon cart-parent" to="/cart" onClick={CloseMenu}>
              <span className="number-of-items">{numOfFormation}</span>
              <PiShoppingCartBold/>

          </Link>
        </div>
      </div>
    </nav>
  );
}
