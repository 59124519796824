import "./FormationCard.css";
import {Link} from "react-router-dom";

function FormationCard({
  formation_link = "",
  description,
  title,
  subtitle,
  image,
}) {
  const style = {
    backgroundImage: `url(${image})`,
  };

  return (
    <Link to={formation_link} className="pricing-card-container" style={style}>
      <div className="pricing-card-container-header">
        <h2 className="pricing-card-container-title bold">{title}</h2>
      </div>
      {/* <p>{description}</p> */}
      <button className="pricing-card-container-button">{subtitle}</button>
    </Link>
  );
}

export default FormationCard;
