//import emptyIcon from "../../media/icons/ecommerce-icon.png";
import {Link} from "react-router-dom";
import {IoArrowBackCircleOutline} from "react-icons/io5";
import React from "react";
import emptynotfoundIcon from '../../media/images/404-v2.png';
import './notFound.css';
import Strie from "../../components/Strie/Strie";

function NotFound() {
    return (
        <section className="carts-empty-container">
            <div className="cart-text">
                <img src={emptynotfoundIcon}
                     className="cart-empty-img"
                     alt="empty-cart"/>
                <h1 className=" no-lettre-spacing"> Oups ! Page non trouvée</h1>
                <p className="centre">Il semble que la page que vous cherchez n'existe pas ou a été déplacée.</p>
                <Link className="cart-empty-link" to='/'><IoArrowBackCircleOutline size={28} className="icon" color="#97179c"/>
                    Retournez à l'accueil
                </Link>
            </div>

            <Strie scale={3}
                   color="blue"
                   top="55%"
                   left="-3%"
                   zindex="-1"
            />
            <Strie scale={5}
                   color="blue"
                   top="68%"
                   zindex= "-1"
                   left="1%"/>
            <Strie scale={5}
                   color="red"
                   left="unset"
                   top="31%"
                   zindex="-1"
                   right="1%"/>
            <Strie
                scale={3}
                color="red"
                left="unset"
                right="-3%"
                top="45%"
                zindex="-1"
            />

        </section>
    );
}

export default NotFound;