import React from "react";
import "./Tab.css";

export default function Tab({ isActive, onClick, children }) {
  return (
    <div className={`tab ${isActive ? "active" : ""}`} onClick={onClick}>
      {children}
    </div>
  );
}
