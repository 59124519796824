export const tabChapters = {
  CGU: [
    { chapterName: "Définitions", sectionId: "cgu-definitions" },
    { chapterName: "Objet", sectionId: "cgu-objet" },
    {
      chapterName: "Description des services",
      sectionId: "cgu-description-services",
    },
    {
      chapterName: "Conditions d'accès au site",
      sectionId: "cgu-conditions-acces-site",
    },
    {
      chapterName: "Inscription - Espace personnel",
      sectionId: "cgu-inscription-espace-personnel",
    },
    {
      chapterName: "Obligations de l'Utilisateur",
      sectionId: "cgu-user-obligations",
    },
    { chapterName: "Signalement", sectionId: "cgu-alert-reporting" },
    {
      chapterName: "Eléments distinctifs du Site - Propriété intellectuelle",
      sectionId: "cgu-website-intelectual-property",
    },
    {
      chapterName: "Responsabilité",
      sectionId: "cgu-obligations",
    },
    {
      chapterName: "Prescription",
      sectionId: "cgu-prescriptions",
    },
    {
      chapterName: "Divisibilité - Interprétation",
      sectionId: "cgu-divisibility-interpretations",
    },
  ],
  CGV: [
    { chapterName: "Définitions", sectionId: "cgv-definitions" },
    { chapterName: "Présentation", sectionId: "cgv-presentation" },
    { chapterName: "Objet", sectionId: "cgv-objet" },
    { chapterName: "Nos services", sectionId: "cgv-our-services" },
    {
      chapterName: "Inscriptions et accès aux services",
      sectionId: "cgv-inscription-acces-services",
    },
    { chapterName: "Durée de la formation", sectionId: "cgv-program-duration" },
    {
      chapterName: "La « Garantie Réussite »",
      sectionId: "cgv-success-guaranty",
    },
    {
      chapterName: "Frais de services et conditions de règlement",
      sectionId: "cgv-fees-and-payment-conditions",
    },
    {
      chapterName: "Droit de rétractation",
      sectionId: "cgv-retraction-rights",
    },
    { chapterName: "Résiliation", sectionId: "cgv-cancellation" },
    { chapterName: "Force majeure", sectionId: "cgv-force-majeure" },
    {
      chapterName: "Obligations des parties",
      sectionId: "cgv-parties-obligations",
    },
    { chapterName: "Responsabilité", sectionId: "cgv-apprentice-duties" },
    {
      chapterName: "Propriété intellectuelle",
      sectionId: "cgv-intellectual-property",
    },
    {
      chapterName:
        "Confidentialité et protection des données à caractère personnel",
      sectionId: "cgv-confidentiality-and-personal-data",
    },
    {
      chapterName: "Suspension de l’accès aux services de formation à distance",
      sectionId: "cgv-remote-education-service-suspension",
    },
    {
      chapterName: "Garantie légale de conformité",
      sectionId: "cgv-legal-conformity-guaranty",
    },
    {
      chapterName: "Droit applicable et règlement des litiges",
      sectionId: "cgv-applicable-rights-and-dispute-settlement",
    },
    { chapterName: "Annexe 1/1", sectionId: "cgv-appendix-1/1" },
  ],
  "Politique de confidentialité": [
    {
      chapterName: "Qui sommes-nous ?",
      sectionId: "confidentiality-who-we-are",
    },
    { chapterName: "Commentaires", sectionId: "confidentiality-commentaries" },
    { chapterName: "Médias", sectionId: "confidentiality-media" },
    {
      chapterName: "Cookies",
      sectionId: "confidentiality-cookies",
    },
    {
      chapterName: "Contenu embarqué depuis d’autres sites",
      sectionId: "confidentiality-third-party-websites-embedded-content",
    },
    {
      chapterName: "Utilisation et transmission de vos données personnelles",
      sectionId: "confidentiality-usage-and-sharing-of-personal-data",
    },
    {
      chapterName: "Durée de stockage de vos données",
      sectionId: "confidentiality-personal-data-storage-duration",
    },
    {
      chapterName: "Les droits que vous avez sur vos données",
      sectionId: "confidentiality-personal-data-rights",
    },
    {
      chapterName: "Où vos données sont-elles envoyées ?",
      sectionId: "confidentiality-where-your-data-is-sent",
    },
  ],
  Accessiblité: [
    {
      chapterName: "Inclusion, respect, écoute",
      sectionId: "phs-listening-respect-inclusion",
    },
    {
      chapterName: "Conditions et modalités d’accès pour les PSH",
      sectionId: "phs-access-conditions-and-phs-requirements",
    },
  ],
};
