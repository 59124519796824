import { useRef, useEffect, useState } from "react";
import { Parallax, Background } from "react-parallax";
import "./HeroSection.css";
import IconNext from "../../../../media/icons/IconNext.jsx";
// import HeroBackground from "../../../../media/images/ado-page-hero-image.webp";
import HeroBackground from "../../../../media/images/ado-page-hero-image-4.webp";

function HeroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <Parallax
      blur={0}
      bgImage={HeroBackground}
      bgImageAlt="ado section"
      bgImageStyle={{ objectFit: "cover", backgroundPosition: "bottom" }}
      strength={400}
    >
      <div className="ado-hero-section-wrapper">
        <div
          ref={sectionRef}
          className={`ado-hero-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <div className="ado-hero-section-body">
            <h1 className="ado-hero-section-title bold">Stages Ados</h1>
            <a href="#list-formations-section" className="ado-hero-section-cta">
              <span>Découvrir</span>
              <IconNext rotation="rotate-down" width={22} height={22} />
            </a>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default HeroSection;
