import {createContext, useContext, useState} from "react";
import secureLocalStorage from "react-secure-storage";


const GlobalContext = createContext();

export const useGlobalContext = () => useContext(GlobalContext);

const AppContext = ({children}) => {

    const [cart, setCart] = useState(
        secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : []
    );

    const updateCart = (updatedCart) => {
        setCart(updatedCart);
        secureLocalStorage.setItem('kids_cart', JSON.stringify(updatedCart)); // Update local storage
    };
    return (
        <GlobalContext.Provider value={{cart,updateCart,setCart}}>
            {children}
        </GlobalContext.Provider>
    );
}

export default AppContext;