import React from "react";

import "./GridComponent.css";

const GridComponents = ({images}) => {
  return (
    <div className="kid-grid-wrapper">
        <img  src={images[0]}/>
        <div>
            <img src={images[1]} />
            <div>
                <img src={images[2]} />
                <img src={images[3]} />
            </div>
        </div>

    </div>
  )
}

export default GridComponents;
