import { useRef, useEffect, useState } from "react";
import { Parallax } from "react-parallax";
import "./HeroSection.css";
import IconNext from "../../../../media/icons/IconNext.jsx";
import { Link } from "react-scroll";
import HeroBackground from "../../../../media/images/background_kids.png";
import HeroBackgroundSmall from "../../../../media/images/background_kids2.png";

function HeroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => {
      console.log("resizing");
      setWindowWidth(window.innerWidth);
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);
  const backgroundImage =
    windowWidth > 1500 ? HeroBackground : HeroBackgroundSmall;
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (
    <Parallax
      blur={0}
      bgImage={backgroundImage}
      bgImageStyle={{ objectFit: "cover", width: "100%", height: "100%" }}
      bgImageAlt="kids section"
      strength={400}
      className="parallax-image-container"
    >
      <div className="kids-hero-section-wrapper">
        <div
          ref={sectionRef}
          className={`kids-hero-section-container ${
            isSectionIntersected ? "visible" : ""
          }`}
        >
          <div className="kids-hero-section-body">
            <h1 className="kids-hero-section-title bold">Ateliers Kids</h1>

            <Link
              to="kids-page-atelier-section-upper-wrapper"
              spy={true}
              smooth={true}
              offset={-70} // Adjust this offset as needed to fit your layout
              duration={500}
              className="kids-hero-section-cta"
            >
              <span>Découvrir</span>
              <IconNext rotation="rotate-down" width={22} height={22} />
            </Link>
          </div>
        </div>
      </div>
    </Parallax>
  );
}

export default HeroSection;
