import "./AtelierSection.css";
import { ateliers } from "./AtelierData";
import React, {useState} from "react";
import image1 from "../../../../media/images/formation2-image2.png";
import image2 from "../../../../media/images/formation2-image1.png";
import image3 from "../../../../media/images/formation2-image3.webp";
import image4 from "../../../../media/images/formation2-image4.png";
import imageGrid2 from "../../../../media/images/formation-image2.png";
import imageGrid3 from "../../../../media/images/formation-image3.png";
import imageGrid4 from "../../../../media/images/formation-image4.png";
import imageGrid5 from "../../../../media/images/formation-image5.png";

import Strie from "../../../../components/Strie/Strie";
import AtelierCard from "./AtelierCard/AtelierCard";

import FormationCourtesData from "../../kids-sections/ateliers-section-2/FormationsCourtesData.json";
import {useGlobalContext} from "../../../../context";

function AtelierSection() {

  const [formations, setFormations] = useState(FormationCourtesData);
  const { cart, updateCart,setCart } = useGlobalContext();

  const [selectedFormation, setSelectedFormation] = useState(null);


  return (
      <div
          className="kids-page-atelier-section-upper-wrapper"
          id="kids-page-atelier-section-upper-wrapper"
      >
        <div className="kids-page-atelier-section-wrapper">
          {/* <h2>Nos Ateliers</h2> */}

          <div className="kids-page-atelier-section-list-wrapper">
            <AtelierCard
                img={[imageGrid2, imageGrid3, imageGrid4, imageGrid5]}
                title={ateliers[0].title}
                desc={ateliers[0].desccription}
                key={ateliers[0].id}
                formation={FormationCourtesData[0]}
                ticket={true}
                cart={cart}
                updateCart={updateCart}
                setCart={setCart}
            />
            <AtelierCard
                img={[image3, image1, image2, image4]}
                title={ateliers[1].title}
                desc={ateliers[1].desccription}
                key={ateliers[1].id}
                formation={FormationCourtesData[1]}
                ticket={true}
                cart={cart}
                updateCart={updateCart}
                setCart={setCart}
                // imageName={FormationCourtesData[1].image_name}
            />
          </div>
        </div>

        <Strie scale={2} color="blue" top="48%" left="-4%" zindex={-1} />
        <Strie scale={3} color="blue" top="50%" zindex={-1} />
        <Strie scale={3} color="red" left="unset" top="58%" zindex={-1} />
        <Strie
            scale={2}
            color="red"
            left="unset"
            right="-4%"
            top="60%"
            zindex={-1}
        />
      </div>
  );
}

export default AtelierSection;
