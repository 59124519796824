import React from "react";
import "./PhsAccessibilitySection.css";
// import Strie from "../../../components/Strie/Strie";

export default function PhsAccessibilitySection() {
  return (
    <>
      <div className="phs-accessibility-notice-wrapper">
        <div className="phs-accessibility-notice-container">
          <div className="phs-accessibility-notice-header">
            <h1>Accessibilité aux publics en situation de handicap</h1>
          </div>
          <div className="phs-accessibility-notice-body">
            <p className="phs-accessibility-notice-section-text">
              Wyze Kids s’adapte à votre situation personnelle pour vous
              accompagner sur le chemin de la réussite.
            </p>
            <div
              className="phs-accessibility-notice-section mt-0"
              id="phs-listening-respect-inclusion"
            >
              <h2 className="phs-accessibility-notice-section-title">
                Inclusion, respect, écoute
              </h2>
              <p className="phs-accessibility-notice-section-text">
                Nos équipes prennent en compte les spécificités des apprenants
                en situation de handicap et ont à coeur à répondre avec
                souplesse et bienveillance à leurs besoins.
              </p>
              <p className="phs-accessibility-notice-section-text">
                Nos équipes peuvent vous orienter vers les instances
                compétentes.
              </p>
            </div>

            {/* <Strie scale={5} culor="red" left="-20%" top="20%" zindex={-1} />
          <Strie scale={2} culor="red" left="-19%" top="140%" zindex={-1} />
          <Strie scale={6} culor="blue" left="115%" top="40%" zindex={-1} /> */}

            <div
              className="phs-accessibility-notices-ection"
              id="phs-access-conditions-and-phs-requirements"
            >
              <h2 className="phs-accessibility-notice-section-title">
                Conditions et modalités d’accès pour les PSH
              </h2>
              <p className="phs-accessibility-notice-section-text">
                L’apprenant PSH bénéficie des conditions d’accès équivalentes à
                un autre apprenant non PSH avec en plus une attention
                particulière aux aménagements pertinents facilitant les
                apprentissages. Les modalités mises en œuvre assurent une prise
                adaptée aux besoins individuels de l’apprenant en situation de
                handicap.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                Avant de vous engager dans une formation, il est fortement
                recommandé d'entamer une discussion avec un référent spécialisé
                dans l'accompagnement des personnes de handicap. Cette
                conversation initiale nous permettra d'examiner la faisabilité
                de votre projet de formation et professionnel et de considérer
                dès le début les accompagnements spécifiques nécessaires. Le
                référent pourra vous conseiller sur les aménagements possibles
                dès ce premier contact.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                Dès votre entrée en formation, un entretien sera organisé pour
                évaluer vos besoins spécifiques. Cela nous aidera à déterminer
                les ajustements nécessaires à mettre en œuvre tout au long de
                votre parcours de formation, jusqu'aux examens. Nos
                intervenants, qu'ils soient référents ou correspondants dédiés
                aux situations de handicap, sont là pour vous fournir des
                réponses adaptées et concevoir un parcours sur mesure pour vous.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                Si, à votre entrée en formation, vous n'avez pas mentionné de
                besoin spécifique ou demandé d'aménagement, sachez que vous avez
                toujours la possibilité de nous en faire part. Il vous suffit de
                nous envoyer un email à{" "}
                <a href="mailto:contact@wyze-kids.com">
                  <span className="bold underline">contact@wyze-kids.com</span>{" "}
                </a>{" "}
                &nbsp;. Un entretien sera ensuite organisé avec vous pour mieux
                comprendre vos besoins et, si nécessaire, nous ferons appel à
                des experts externes pour faciliter la mise en place des
                contact@wyze-kids.com relation avec les apprenants pour initier
                une conversation avec un référent ou un correspondant handicap
                dans un délai de moins de 7 jours.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                La planification de votre accompagnement inclura l'élaboration
                d'un plan personnalisé, accessible à l'équipe chargée de votre
                suivi. Ce plan détaillera la fréquence des interactions entre
                vous et votre correspondant handicap.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                Nous nous engageons à adapter les méthodes pédagogiques, et à
                aménager les lieux d'examens en fonction de vos besoins. Ces
                adaptations peuvent inclure l'ajustement des supports
                pédagogiques et des méthodes d'enseignement, en plus des
                aménagements standards que nous proposons (par exemple,
                sous-titrage des vidéos, utilisation de polices de caractères
                plus grandes, matériel spécifique).
              </p>
              <p className="phs-accessibility-notice-section-text">
                Votre suivi sera régulier, avec des échanges téléphoniques et
                des rendez pour s'assurer que l'accompagnement est toujours
                adapté à vos besoins. Nous mettrons également en œuvre des
                modalités spécifiques pour les examens, comme l'octroi de temps
                supplémentaire ou l'adaptation de votre poste d'examen.
              </p>
              <p className="phs-accessibility-notice-section-text">
                Enfin, à la fin de votre formation, nous évaluerons ensemble les
                résultats et l'efficacité des adaptations mises en place, afin
                de s'assurer que vous avez bénéficié du meilleur accompagnement
                possible.
              </p>
              <br />
              <p className="phs-accessibility-notice-section-text">
                Pour les personnes malvoyantes, il est possible d’installer le
                logiciel Zoomtext gratuitement.
              </p>
            </div>
          </div>
        </div>
        <div className="phs-accessibility-notice-footer">
          <span>
            <p>07 45 62 88 26</p>
            <p>
              <a
                href="mailto:secretariat@wyze-kids.com"
                aria-label="Contacter le secretariat via email"
              >
                <span className="bold unerline">secretariat@wyze-kids.com</span>
              </a>
            </p>
            <p> Siège social : 21 avenue Raspail 93420 VILLEPINTE </p>
          </span>
        </div>
      </div>
    </>
  );
}
