import "./CartElement.css";

import { FaRegTrashAlt } from "react-icons/fa";
import { FaPlus } from "react-icons/fa";
import { FaMinus } from "react-icons/fa";
import React, {useEffect, useState} from "react";
import  secureLocalStorage  from  "react-secure-storage";

function CartElement({ element,deleteItem ,updateCart}) {

  const [itemQuantity,setItemQuantity] =  useState(element.nbr_enfant)    // for suiting number of children
    const [selectedDate, setSelectedDate] = useState(element.date_choisi || element.formation_dates[0]);


    let imageUrl;
  try {
    imageUrl = require(`../../media/images/${element.image_name}`);
  } catch (error) {
    console.error(error);
    imageUrl = null;
  }


    useEffect(() => {
        if (element.nbr_enfant !== itemQuantity) {
            const cartuse = secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : [];
            const updatedCart = cartuse.map((item) =>
                item.course_reference === element.course_reference
                    ? { ...item, nbr_enfant: itemQuantity }
                    : item
            );
            secureLocalStorage.setItem("kids_cart", JSON.stringify(updatedCart));
            updateCart(updatedCart); // Notify parent to update total
        }
    }, [itemQuantity, element.course_reference,element.nbr_enfant, updateCart]);

    // Update cart when the date changes
    useEffect(() => {
        if (element.date_choisi !== selectedDate) {
            const cartuse = secureLocalStorage.getItem('kids_cart') ? JSON.parse(secureLocalStorage.getItem('kids_cart')) : [];
            const updatedCart = cartuse.map((item) =>
                item.course_reference === element.course_reference
                    ? { ...item, date_choisi: selectedDate }
                    : item
            );
            secureLocalStorage.setItem("kids_cart", JSON.stringify(updatedCart));
            updateCart(updatedCart); // Notify parent to update total
        }
    }, [selectedDate, element.course_reference, element.date_choisi,updateCart]);


    const handleDateChange = (e) => {
        setSelectedDate(e.target.value);
    };
 
  return (
    <div className="cart-element">
        {imageUrl && (
            <div className="img-container">
                <img src={imageUrl} className="cart-element-img" alt="element-formation"/>
            </div>

        )}
        <div className="cart-element-detail">
            <div className="flex-column ">
                <p>Titre:<span>  {element.formation_title}</span></p>
                <p>Type:<span>  {element.formation_type}</span></p>
                <p>Prix:<span>  {element.formation_prix}€</span></p>
                <p>Lieu:<span>  {element.formation_lieu}</span></p>
                <p>Durée:<span>  {element.formation_duree}</span></p>
            </div>

            <div className="date-childe">
                <div className="flex-column">
                    <label>Date</label>
                    <select value={selectedDate}
                            onChange={handleDateChange}>
                        {
                            element.formation_dates.map((date) => {
                                return (
                                    <option key={date}
                                            value={date}>{date}</option>

                                )
                            })
                        }
                    </select>
                </div>
                <div className="quantity">
                    <label>Enfants</label>
                    <div className="incrementor-decrementor">
                        <button id="add"
                                className="btn"
                                onClick={() => setItemQuantity((prev) => prev + 1)}><FaPlus className="icon-add"/></button>
                        <span id="valueQuant" className="quantity-value">{itemQuantity}</span>
                        <button id="sub"
                                className="btn"
                                onClick={() => setItemQuantity((prev) => prev > 1 ? prev - 1 : 1)}>
                            <FaMinus className="icon-add" />
                        </button >
                        <button onClick={() => deleteItem(element.course_reference)} className="quantity-delete">
                            <FaRegTrashAlt/>
                        </button>

                    </div>
                </div>

            </div>
        </div>

    </div>
  );
}

export default CartElement;
