import Accordion from "../../../../components/Accordion/Accordion";
import Strie from "../../../../components/Strie/Strie";
import "./FaqSection.css";

function FaqSection() {
  return (
    <section id="faq-section" className="faq-section-wrapper">
      <div className="faq-section-container">
        <div className="faq-section-header">
          <h1 className="faq-section-subtitle">FAQ</h1>
          <h2 className="semi-bold faq-section-title">
            Questions fréquemment posées
          </h2>
        </div>
        <div className="faq-section-body">
          <Accordion title={"L'Hacienda : c'est pour qui ?"}>
            <p className="faq-response">
              Nous accueillons les enfants et adolescents de 7 à 18 ans dans nos
              locaux de l’Hacienda Studio à Saint-Ouen.
            </p>
          </Accordion>
          <Accordion title={"L'Hacienda : Parents friendly"}>
            <p className="faq-response">
              Un espace de détente en extérieur et un espace de co-working en
              intérieur sont à votre disposition. <br />
              <br />
              Vous pouvez restez sur place tout ou partie de la journée en
              attendant votre ou vos enfants.
            </p>
          </Accordion>
          <Accordion
            title={"L'Hacienda : un espace sécurisé et de restauration"}
          >
            <p className="faq-response">
              L’Hacienda de Saint-Ouen dispose d’un espace extérieur clos et
              sécurisé : les enfants peuvent en profiter pour jouer sur les
              temps de pause.
              <br />
              <br />
              Un espace restauration avec une véritable cuisine vous permet de
              réchauffer le plat de votre enfant ou bien même de faire la
              cuisine sur place !
            </p>
          </Accordion>
          <Accordion title={"L'Hacienda : Aurai-je besoin de matériel ?"}>
            <p className="faq-response">
              En présentiel, chaque enfant ou adolescent dispose d’un ordinateur
              connecté à Internet haut débit.
              <br />
              <br />
              En distanciel, vous aurez besoin d’un PC/MAC et d’une connexion
              Internet.{" "}
            </p>
          </Accordion>
          <Accordion title={"Qui sont nos formateurs ?"}>
            <p className="faq-response">
              Tous nos formateurs sont diplômés et des passionnés de
              technologie. Ils sont formés exprès pour dispenser la pédagogie
              Wyze Academy adaptée aux enfants et aux adolescents, qu’ils soient
              débutants ou confirmés. Chaque formateur a sa propre
              spécialisation pour inspirer et propulser les Wyzers vers
              l’excellence !
            </p>
          </Accordion>
          <Accordion title={"Aurai-je des supports de cours ?"}>
            <p className="faq-response">
              Les supports seront remis à chaque adolescent au fur-et-à-mesure
              des ateliers. Ils sont certifiés par l’équipe pédagogique de Wyze
              Academy. <br /> <br />
              Chaque enfant repartira, en fin de semaine, avec toutes ses
              productions.
            </p>
          </Accordion>
          <Accordion
            title={
              "Quel est le nombre de participants par session de formation ?"
            }
          >
            <p className="faq-response">
              Pour les kids, nous limitons le groupe à 10 enfants avec deux
              encadrantes.
            </p>
          </Accordion>
          <Accordion title={"Quels sont les horaires de ma formation ?"}>
            <p className="faq-response">
              Les ateliers à l’Hacienda pour les 7/14 ans se découpent en
              demi-journée : <br/> de 10h à 12h30 puis de 14h à 16h30.
              <br /> <br />
              Les stages pour les ados (en présentiel à l’Hacienda ou en
              distanciel) débutent à 10h et se terminent à 16h (heure de Paris)
              avec une heure de pause méridienne.
            </p>
          </Accordion>
        </div>
      </div>
      <Strie scale={3} color="red" left="-3%" top="60%" zindex={-1} />
      <Strie scale={5} color="red" left="1%" top="75%" zindex={-1} />
      <Strie scale={3} color="blue" left="96%" top="39%" zindex={-1} />
      <Strie scale={5} color="blue" left="92%" top="24%" zindex={-1} />
    </section>
  );
}

export default FaqSection;
