import "./AdoPage.css";
import { Helmet } from "react-helmet";
import HeroSection from "../ado-page/ado-sections/hero-sections/HeroSection";
// import ListFormationSection from "./ado-sections/list-formations-sections/ListFormationsSection";
import ListFormationsSection3 from "./ado-sections/list-formations-section-3/ListFormationsSection3";
function AdoPage() {
  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <title>Wyze Academy</title>
        <meta
          name="description"
          content="Formations numériques pour ado, courtes ou longues, distanciel ou présentiel, éligibles CPF, certifiantes et micro-certifiantes"
        />
        <meta
          name="keywords"
          content="formations courtes, formations longues, adultes, en présentiel, en distanciel"
        />
      </Helmet>
      <div className="ado-page-wrapper">
        <div className="hero-section">
          <HeroSection />
        </div>
        <div className="list-formation-section">
          {/* <ListFormationSection /> */}
          <ListFormationsSection3 />
        </div>
      </div>
    </>
  );
}

export default AdoPage;
