import { Parallax } from "react-parallax";
import "./BannerSection.css";
import BannerImage from "../../../../media/images/banner-image.jpg";
import { useState, useEffect } from "react";

function BannerSection() {
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });

  useEffect(() => {
    const handleMouseMove = (event) => {
      setCursorPosition({
        x: event.clientX,
        y: event.clientY,
      });
    };

    window.addEventListener("mousemove", handleMouseMove);

    // Cleanup the event listener on component unmount
    return () => {
      window.removeEventListener("mousemove", handleMouseMove);
    };
  }, []);

  return (
    <div className="banner-section-wrapper">
      <Parallax
        blur={0}
        bgImage={BannerImage}
        bgImageAlt="banner wyze kids"
        bgImageStyle={{ scale: 1.5 }}
        strength={400}
      >
        <div
          className="banner-section-formation-section-triangle"
          style={{ left: cursorPosition.x }}
        ></div>
        <div className="banner-section-container"></div>
      </Parallax>
    </div>
  );
}

export default BannerSection;
