import { useRef, useEffect, useState } from "react";
import "./IntroSection.css";
import Card from "../../../../components/Card/Card";
import Strie from "../../../../components/Strie/Strie";
import CertificationIcon from "../../../../media/icons/certification-icon.png";
import FormateurIcon from "../../../../media/icons/formateur-icon.png";
import BuildIcon from "../../../../media/icons/build-icon.png";

function IntroSection() {
  /*animation d'apparition sur intersection*/
  const sectionRef = useRef();
  const [isSectionIntersected, setIsSectionIntersected] = useState();
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      const entry = entries[0];
      setIsSectionIntersected(entry.isIntersecting);
    });
    observer.observe(sectionRef.current);
    return () => {
      observer.disconnect();
    };
  });

  return (

    <div className="home-sections-wrapper">
      <div
        ref={sectionRef}
        className={`intro-section-container transparent-container ${
          isSectionIntersected ? "visible" : ""
        }`}
      >
        <div className="intro-section-header">
          <h1 className="intro-title">Wyze Kids</h1>
          <h2 className="semi-bold intro-section-title">Excellence & Fun</h2>
          <p className="intro-section-text">
            Bienvenue sur notre site web dédié à la{" "}
            <span className="bold">formation numérique</span> pour les enfants
            et adolescents de <span className="bold">7 à 18 ans</span> !
          </p>
          <p className="intro-section-text">
            Nous sommes ravis de vous présenter notre centre de formation
            innovant, où les enfants/ados peuvent explorer le monde passionnant
            du <span className="bold">développement web</span>, de la{" "}
            <span className="bold">création graphique</span>, de{" "}
            <span className="bold">l’intelligence artificielle</span>, de la {" "}
            <span className="bold">cybersécurité</span>, de la{" "}
            <span className="bold">création vidéo</span> sur{" "}
            <span className="bold">fond vert</span> et du{" "}
            <span className="bold">podcasting</span>.
          </p>
          <p className="intro-section-text">
            Notre équipe de <span className="bold">formateurs</span>{" "}
            expérimentés propose une{" "}
            <span className="bold">pédagogie adaptée</span> aux plus jeunes.
          </p>
        </div>
        <a href="#formation-section" className="intro-section-button">
          <span className="intro-section-button-weight">Contactez nous</span>
          {/* <IconNext rotation="rotate-down" width={22} height={22} /> */}
        </a>
      </div>
      <Strie scale={3} color="red" left="-20%" top="77%" zindex="-1" />
      <Strie scale={5} color="red" left="-14%" top="98%" zindex="-1" />

      <Strie scale={5} color="blue" left="108%" top="35%" zindex="-1" />
      <Strie scale={3} color="blue" left="112%" top="58%" zindex="-1" />
    </div>
  );
}

export default IntroSection;
