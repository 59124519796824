import React, {useEffect, useRef, useState} from "react";
import "./ListFormationSection3.css";
import FormationsPresentielData from "./FormationsPresentielData.json";
import FormationsDistancielData from "./FormationsDistancielData.json";
import FormationCard from "./FormationCard3";
import Strie from "../../../../components/Strie/Strie";
import AdoFormationInfoDetails from "./AdoFormationInfoDetails";
import Contact from "../../../../components/Contact/Contact";
import { Link } from "react-router-dom";
import secureLocalStorage from "react-secure-storage";
import {useGlobalContext} from "../../../../context";

const FormationList3 = () => {
    const [formations, setFormations] = useState(FormationsPresentielData.filter(formation => formation.formation_period === "week-end"));
    const { cart, updateCart,setCart } = useGlobalContext();
    const [selectedFormation, setSelectedFormation] = useState(null);
    const [selectSubPresent, setSelectSubPresent] = useState(true)
    const [selectedPeriod, setSelectedPeriod] = useState("week-end") // Track selected period
    const [loading, setLoading] = useState(false); // Loading state


    const moreDialogRef = useRef();
  const moreScrollableRef = useRef();
  const contactDialogRef = useRef();
  const contactScrollableRef = useRef();

  const openModal = (ref, scrollRef, formation) => {
    setSelectedFormation(formation);
    ref.current?.showModal();
    // document.body.style.overflow = "hidden";
    scrollRef.current.scrollTo(0, 0);
  };

  const closeModal = (ref) => {
    ref.current?.close();
    // document.body.style.overflow = "unset";
  };

    // Update the cart count when it's changed
    useEffect(() => {
        const updatedCart = secureLocalStorage.getItem('kids_cart')
            ? JSON.parse(secureLocalStorage.getItem('kids_cart'))
            : [];
        setCart(updatedCart);
    }, []);

    // Function to handle loading and filtering formations
    const handleFilter = (period) => {
        setLoading(true); // Start loading
        setTimeout(() => {
            // Simulating a loading delay (for demonstration purposes)
            setFormations(FormationsPresentielData.filter(formation => formation.formation_period === period));
            setSelectedPeriod(period);
            setLoading(false); // End loading
        }, 1000); // Simulate a 1-second loading time
    };

  return (
    <div id="list-formations-section" className="list-formation-wrapper">
      <div className="list-formation-container">
        <div className="list-formation-header">
          <Link
              className={`list-formation-button ${(selectSubPresent || selectedPeriod) ? "active" : "inactive btn btn1"}`}

              to="#list-formations-section"
            onClick={() => {
                setFormations(FormationsPresentielData.filter(formation => formation.formation_period === "week-end"));
                setSelectSubPresent(true)
                setSelectedPeriod("week-end"); // Reset period selection
            }}
          >
            Présentiel
          </Link>
          <Link
              className={`list-formation-button ${!selectSubPresent && !selectedPeriod ? "active" : "inactive btn btn1"}`}

              // href="#list-formations-section"
            to="#list-formations-section"
            onClick={() => {
                setFormations(FormationsDistancielData)
                setSelectSubPresent(false)
                setSelectedPeriod(null); // Reset period selection
            }}
          >
            Distanciel
          </Link>
        </div>
          {selectSubPresent && <div className="list-formation-container">
              <p className="list-formation-title">Choisissez la période pour votre formation qui vous convient </p>
             <div className="formation-period">
                 <Link
                     className={`list-formation-link ${selectedPeriod === 'week-end' ? "active" : "inactive btn btn1"}`}
                     to="#list-formations-section"
                     onClick={() => handleFilter('week-end')}
                 >
                     Soir & Week-end
                 </Link>
                 <Link
                     className={`list-formation-link ${selectedPeriod === 'vacances scolaires' ? "active" : "inactive btn btn1"}`}
                     to="#list-formations-section"
                     onClick={() => handleFilter('vacances scolaires')}
                 >
                     Vacances Scolaires
                 </Link>
             </div>
          </div>}
          <div className="list-formation-body">
              <div className="formation-cards-container">
                  {formations.map((formation, index) => (
                      <FormationCard
                          imageName={formation.image_name}
                          key={index}
                          formation={formation}
                          openModal={openModal}
                          moreDialogRef={moreDialogRef}
                          moreScrollableRef={moreScrollableRef}
                          contactDialogRef={contactDialogRef}
                          contactScrollableRef={contactScrollableRef}
                          updateCart={updateCart}
                          cart={cart}
                          setCart={setCart} // Pass setCart to FormationCard
                      />
                  ))}
              </div>
          </div>
      </div>

        <Strie scale={2}
               color="red"
               top="44%"
               left="-3%"
               zindex={-1}/>
        <Strie scale={4}
               color="red"
               top="50%"
               zindex={-1} />
      <Strie scale={4} color="blue" left="unset" top="54%" zindex={-1} />
      <Strie
        scale={2}
        color="blue"
        left="unset"
        right="-3%"
        top="60%"
        zindex={-1}
      />

      <dialog className="formation-ado-more-modal" ref={moreDialogRef}>
        <div
          ref={moreScrollableRef}
          className="formation-ado-more-modal-wrapper"
        >
          {selectedFormation && (
            <AdoFormationInfoDetails formation={selectedFormation} type={formations} />
          )}
        </div>
        <button
          className="ado-formation-details-close-button"
          onClick={() => closeModal(moreDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>

      <dialog
        id="dialooog"
        className="formation-ado-contact-modal"
        ref={contactDialogRef}
      >
        <div
          ref={contactScrollableRef}
          className="formation-ado-contact-modal-wrapper"
        >
          {selectedFormation && (
            <Contact subject={selectedFormation.formation_title} />
          )}
        </div>
        <button
          className="ado-formation-details-close-button"
          onClick={() => closeModal(contactDialogRef)}
        >
          &#x2715;
        </button>
      </dialog>
    </div>
  );
};

export default FormationList3;
